import image_1 from 'src/assets/client-images/Objects/OldSite/Bobruisk Arena.jpg';
import image_2 from 'src/assets/client-images/Objects/OldSite/Chizhovka Arena, Minsk.jpg';
import image_3 from 'src/assets/client-images/Objects/OldSite/P4280005.jpg';
import image_4 from 'src/assets/client-images/Objects/OldSite/Slantsy cement plant CESLA Leningrad region.jpg';
import image_5 from 'src/assets/client-images/Objects/OldSite/Belarusian cement plant, Krichev.jpg';
import image_6 from 'src/assets/client-images/Objects/OldSite/Sports and entertainment center of Molodechno.jpg';
import image_7 from 'src/assets/client-images/Objects/OldSite/Minsk Arena.jpg';
import image_8 from 'src/assets/client-images/Objects/OldSite/Multifunctional complex Baltic Pearl, St. Petersburg.jpg';
import image_9 from 'src/assets/client-images/Objects/OldSite/Ice Palace Baranovichi.jpg';
import image_10 from 'src/assets/client-images/Objects/OldSite/Sports and entertainment complex in Soligorsk.jpg';
import image_11 from 'src/assets/client-images/Objects/OldSite/The main concert venue of the international arts festival Slavianski Bazaar in Vitebsk.jpg';
import image_12 from 'src/assets/client-images/Objects/MinskIcePalace.jpg';
import image_13 from 'src/assets/client-images/Objects/OldSite/Football arena in Minsk.jpg';
import image_14 from 'src/assets/client-images/Objects/OldSite/National Library.jpg';
import image_15 from 'src/assets/client-images/Objects/OldSite/Newspaper factory Asnova Shklov.jpg';
import image_16 from 'src/assets/client-images/Objects/OldSite/Borisov Arena.jpg';
import image_17 from 'src/assets/client-images/Objects/AcademyOfSciencesMoscow.jpg';
import image_18 from 'src/assets/client-images/Objects/BayonetObeliskBrestFortress.jpg';

interface ISlidePhotos {
  id: number;
  image: string;
  title: string;
  text: string;
}

export const slidePhotos: ISlidePhotos[] = [
  {
    id: 1,
    image: `${image_1}`,
    title: 'Бобруйск-Арена',
    text: 'Бобруйск-Арена',
  },
  {
    id: 2,
    image: `${image_2}`,
    title: 'Чижовка-Арена',
    text: 'Чижовка-Арена',
  },
  {
    id: 3,
    image: `${image_3}`,
    title: 'Базовая станция оператора мобильной связи',
    text: 'Базовая станция оператора мобильной связи',
  },
  {
    id: 4,
    image: `${image_4}`,
    title: 'Сланцевский цементный завод «ЦЕСЛА»',
    text: 'Сланцевский цементный завод «ЦЕСЛА»',
  },
  {
    id: 5,
    image: `${image_5}`,
    title: 'Белорусскмй цементный завод',
    text: 'Белорусскмй цементный завод',
  },
  {
    id: 6,
    image: `${image_6}`,
    title: 'Спортивно-развлекательный центр',
    text: 'Спортивно-развлекательный центр',
  },
  {
    id: 7,
    image: `${image_7}`,
    title: 'Минск-Арена',
    text: 'Минск-Арена',
  },
  {
    id: 8,
    image: `${image_8}`,
    title: 'Многофункциональный комплекс Балтийская жемчужина',
    text: 'Многофункциональный комплекс Балтийская жемчужина',
  },
  {
    id: 9,
    image: `${image_9}`,
    title: 'Ледовый дворец',
    text: 'Ледовый дворец',
  },
  {
    id: 10,
    image: `${image_10}`,
    title: 'Спортивно-зрелищный комплекс',
    text: 'Спортивно-зрелищный комплекс',
  },
  {
    id: 11,
    image: `${image_11}`,
    title: 'Концертная площадка международного фестиваля искусств',
    text: 'Концертная площадка международного фестиваля искусств',
  },
  {
    id: 12,
    image: `${image_12}`,
    title: 'Минский ледовый дворец',
    text: 'Минский ледовый дворец',
  },
  {
    id: 13,
    image: `${image_13}`,
    title: 'Футббольный манеж',
    text: 'Футббольный манеж',
  },
  {
    id: 14,
    image: `${image_14}`,
    title: 'Национальная библиотека',
    text: 'Национальная библиотека',
  },
  {
    id: 15,
    image: `${image_15}`,
    title: 'Завод газетной бумаги Аснова',
    text: 'Завод газетной бумаги Аснова',
  },
  {
    id: 16,
    image: `${image_16}`,
    title: 'Борисов-Арена',
    text: 'Борисов-Арена',
  },
  {
    id: 17,
    image: `${image_17}`,
    title: 'Здание Академии Наук',
    text: 'Здание Академии Наук',
  },
  {
    id: 18,
    image: `${image_18}`,
    title: 'Штык-обелиск Брестская крепость',
    text: 'Штык-обелиск Брестская крепость',
  },
];
