import { configureStore } from '@reduxjs/toolkit';
import sidebarReducer from 'src/features/sidebar/sidebarSlice';
import accessibilityReducer from 'src/features/accessibility/accessibilitySlice';
import tabsReducer from 'src/features/tabs/tabsSlice';

const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    accessibility: accessibilityReducer,
    tabs: tabsReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
