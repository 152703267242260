import React from 'react';
import PageTemplate from '../../components/ModulesForPages/PageTemplate';
import Banner from '../../components/Elements/Banner';
import style from './AboutPage.module.scss';
import { Link } from 'react-router-dom';
import Tabs from 'src/components/Modules/Tabs';
import ProductionCapacity from 'src/components/ModulesForPages/About/ProductionCapacity';
import Certificates from 'src/components/ModulesForPages/About/Certificates';
import Management from 'src/components/ModulesForPages/About/Management/Management';
import DetailedHistory from 'src/components/ModulesForPages/About/DetailedHistory';
import DetailedHistorybla from 'src/components/ModulesForPages/About/DetailedHistorybla';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const AboutPage = () => {
  const { t } = useTranslation();

  const aboutTabs = [
    {
      id: '1',
      label: t('about.production_facilities'),
      content: <ProductionCapacity />,
    },
    { id: '2', label: t('about.certificates'), content: <Certificates /> },
    { id: '3', label: t('about.management'), content: <Management /> },
    { id: '4', label: t('about.history'), content: <DetailedHistory /> },
    // { id: '5', label: 'История2', content: <DetailedHistorybla /> },
  ];
  return (
    <PageTemplate>
      <Helmet>
        <title>
          {t('breadcrumbs.about')} | {t('bannerTitles.companyName')}
        </title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Официальный сайт ОАО «Молодечненский завод металлоконструкций»."
        />
        <link rel="canonical" href="https://mzmk.by/about" />
      </Helmet>
      <Banner titleKey="about" />
      <div className={style.aboutPage}>
        <div className={style.container}>
          <Tabs tabs={aboutTabs} namespace="aboutTabs" />
        </div>
      </div>
    </PageTemplate>
  );
};

export default AboutPage;
