import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './A11yAppealsPage.module.scss';
import A11yPageTemplate from 'src/components/Accessibility/A11yModulesForPages/A11yPageTemplate';
import { Helmet } from 'react-helmet-async';

const A11yAppealsPage = () => {
  const { t } = useTranslation();

  return (
    <A11yPageTemplate>
      <Helmet>
        <title>
          {t('breadcrumbs.appeals')} | {t('bannerTitles.companyName')}
        </title>
        <meta name="robots" content="noindex, nofollow" />
        <meta
          name="description"
          content="Страница доступности ОАО «Молодечненский завод металлоконструкций» для пользователей с нарушением зрения. Настройки шрифтов и цветовых схем для удобства просмотра."
        />
      </Helmet>
      <div className={style.a11yAppealsPage}>
        <>
          <h1>{t('sideBar.appeals')}</h1>
          <p>
            {t('electronicAppeals.intro.0')}&nbsp;
            <a href="https://xn--80abnmycp7evc.xn--90ais/">
              {t('electronicAppeals.intro.1')}
            </a>
            &nbsp;
            <a href="https://xn--80abnmycp7evc.xn--90ais/">
              {t('electronicAppeals.intro.2')}
            </a>
            {'. '}
          </p>
          <p>{t('electronicAppeals.languageRequirement')}</p>
          <p>
            <a target="_blank" href="/documents/zakon.pdf">
              {t('electronicAppeals.lawReference.0')}
            </a>
            {', '}
            <a target="_blank" href="/documents/zakon2.pdf">
              {t('electronicAppeals.lawReference.1')}
            </a>
            {'. '}
          </p>
        </>
        <>
          <h3>{t('electronicAppeals.mandatoryFields')} </h3>
          <ul>
            <li>{t('electronicAppeals.mandatoryFieldsList.0')}</li>
            <li>{t('electronicAppeals.mandatoryFieldsList.1')}</li>
            <li>{t('electronicAppeals.mandatoryFieldsList.2')}</li>
            <li>{t('electronicAppeals.mandatoryFieldsList.3')}</li>
          </ul>
          <p>{t('electronicAppeals.response')}</p>
        </>
        <>
          <h3>{t('electronicAppeals.notConsidered')} </h3>
          <ul>
            <li>{t('electronicAppeals.notConsideredList.0')}</li>
            <li>{t('electronicAppeals.notConsideredList.1')}</li>
            <li>{t('electronicAppeals.notConsideredList.2')}</li>
            <li>{t('electronicAppeals.notConsideredList.3')}</li>
            <li>{t('electronicAppeals.notConsideredList.4')}</li>
            <li>{t('electronicAppeals.notConsideredList.5')}</li>
            <li>{t('electronicAppeals.notConsideredList.6')}</li>
            <li>{t('electronicAppeals.notConsideredList.7')}</li>
            <li>{t('electronicAppeals.notConsideredList.8')}</li>
          </ul>
        </>
      </div>
    </A11yPageTemplate>
  );
};

export default A11yAppealsPage;
