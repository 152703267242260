import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as EyeIconFill } from 'src/assets/icons/visibilityEye_FILL.svg';
import style from './EyeButton.module.scss';
import {
  setColorScheme,
  selectAccessibility,
} from 'src/features/accessibility/accessibilitySlice';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const EyeButton = () => {
  const { t } = useTranslation();
  const { colorScheme } = useSelector(selectAccessibility);

  return (
    <a href="/" className={style.a11yButton}>
      <EyeIconFill className={`${style.icon} ${colorScheme}-scheme-icon`} />
      <span>{t('eye.title')}</span>
    </a>
  );
};

export default EyeButton;
