import React from 'react';
import style from './DetailedHistory.module.scss';

import { ReactComponent as Image_1 } from 'src/assets/icons/Mzmk_logotype.svg';

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

interface TimelineElement {
  id: number;
  title: string;
  location?: string;
  description: string;
  buttonText?: string;
  date: string;
  icon: string;
  imageUrl?: string;
}

const timelineElements: TimelineElement[] = [
  {
    "id": 1,
    "date": "30 декабря 1963 года",
    "title": "Сдана в эксплуатацию 1 очередь завода металлоконструкций",
    "description": "Молодечненский завод лёгких металлоконструкций был организован в августе 1980 г. слиянием двух заводов: металлоконструкций и лёгких металлоконструкций. Первый был введён в эксплуатацию в 1968 году, а второй в 1978 году.",
    "icon": "work",
    "buttonText": "",
    "imageUrl": ""
  },
  {
    "id": 2,
    "date": "21 февраля 1965 года",
    "title": "Создана дирекция строящегося завода",
    "description": "Приказом Министра монтажных и специальных строительных работ Якубовского Ф.Б. от 21 февраля 1965 года в числе треста «Стальконструкция» создана дирекция строящегося Молодечненского завода металлоконструкций.",
    "icon": "work",
    "buttonText": "",
    "imageUrl": ""
  },
  {
    "id": 3,
    "date": "12 мая 1968 года",
    "title": "Назначение директора строящегося завода",
    "description": "В связи с приказом № 125 по «Стальконструкция» от 12 мая 1968 директором строящегося завода назначен Калинин Семен Кузьмич.",
    "icon": "work",
    "buttonText": "",
    "imageUrl": ""
  },
  {
    "id": 4,
    "date": "25 ноября 1968 года",
    "title": "Принятие завода в эксплуатацию",
    "description": "Калинин Семен Кузьмич принял завод в эксплуатацию.",
    "icon": "work",
    "buttonText": "",
    "imageUrl": ""
  },
  {
    "id": 5,
    "date": "Декабрь 1968 года",
    "title": "Начат выпуск первой продукции",
    "description": "С декабря 1968 начат выпуск первой продукции.",
    "icon": "work",
    "buttonText": "",
    "imageUrl": ""
  },
  {
    "id": 6,
    "date": "7 декабря 1968 года",
    "title": "Новоселье работников завода",
    "description": "7 декабря счастливые работники завода праздновали новоселье в 70-ти квартирном жилом здании по ул. Хмельницкого, 3б.",
    "icon": "work",
    "buttonText": "",
    "imageUrl": ""
  },
  {
    "id": 7,
    "date": "1972 год",
    "title": "Выполнение крупных заказов",
    "description": "В 1972 году завод выполнил заказы для Смедеревского металлургического комбината в Югославии и металлургического комбината в Исфахане (Иран). Завод также выполнил сложный заказ - штык-обелиск мемориального комплекса в Брестской крепости, за что был внесен в число участников ВДНХ и награжден дипломом и медалями выставки.",
    "icon": "work",
    "buttonText": "",
    "imageUrl": ""
  },
  {
    "id": 8,
    "date": "5 июня 1973 года",
    "title": "Назначение нового директора",
    "description": "5 июня 1973 года на должность директора был назначен Сусленков Юрий Федорович.",
    "icon": "work",
    "buttonText": "",
    "imageUrl": ""
  },
  {
    "id": 9,
    "date": "1973 год",
    "title": "Выполнение заказов для Лебединского ГОКа",
    "description": "Завод выполняет металлоконструкции для Лебединского ГОКа.",
    "icon": "work",
    "buttonText": "",
    "imageUrl": ""
  },
  {
    "id": 10,
    "date": "1974 год",
    "title": "Заказы Новогрудского химкомбината",
    "description": "Завод выполняет заказы Новогрудского химкомбината и металлургического завода в Иране.",
    "icon": "work",
    "buttonText": "",
    "imageUrl": ""
  },
  {
    "id": 11,
    "date": "1975-1976 годы",
    "title": "Освоение новых типов конструкций",
    "description": "Завод осваивает новые типы конструкций, такие как каркас башни копра Солигорского калийного комбината и панели покрытия.",
    "icon": "work",
    "buttonText": "",
    "imageUrl": ""
  },
  {
    "id": 12,
    "date": "1977 год",
    "title": "Производство осветительных башен",
    "description": "Завод производит осветительные башни для большой спортивной арены Центрального стадиона имени В.И. Ленина в Лужниках и Минского стадиона \"Динамо\".",
    "icon": "work",
    "buttonText": "",
    "imageUrl": ""
  },
  {
    "id": 13,
    "date": "1978 год",
    "title": "Производство для Олимпиады-80",
    "description": "Завод производит металлоконструкции для Олимпиады-80 и осваивает выпуск подмоторных рам для кормоуборочного комбайна KCK-100.",
    "icon": "work",
    "buttonText": "",
    "imageUrl": ""
  },
  {
    "id": 14,
    "date": "20 июня 1973 года",
    "title": "Назначение нового директора",
    "description": "На должность директора был назначен Линкин Дмитрий Николаевич. Завод стабильно работал и выпускал продукцию не только на внутренний рынок, но и за рубеж.",
    "icon": "work",
    "buttonText": "",
    "imageUrl": ""
  },
  {
    "id": 15,
    "date": "28 июля 1975 года",
    "title": "Назначение Самарина Станислава Ивановича",
    "description": "На основе приказа № 30-К от 28 июля 1975 года на Главспецлегконструкций вступил в должность директора будущего Молодечненского завода легких металлоконструкций Самарин Станислав Иванович.",
    "icon": "work",
    "buttonText": "",
    "imageUrl": ""
  },
  {
    "id": 16,
    "date": "30 мая 1979 года",
    "title": "Высокая оценка достижений завода",
    "description": "Министр монтажных и специальных строительных работ Л. Солоденников дал высокую оценку достижениям завода в статье «Отрасль завтрашнего дня», опубликованной в «Строительной газете». Завод стал пионером в использовании прокатной продукции мощностью в сто тысяч тонн прогрессивных профилей.",
    "icon": "work",
    "buttonText": "",
    "imageUrl": ""
  },
  {
    "id": 17,
    "date": "Август 1980 года",
    "title": "Объединение заводов",
    "description": "В августе 1980 года завод металлоконструкций и завод легких металлоконструкций были объединены в одно предприятие: организован завод комплектной поставки металлоконструкций на основе приказа Минмонтажспецбуда СССР № 193 от 22 июня 1960 года.",
    "icon": "work",
    "buttonText": "",
    "imageUrl": ""
  },
  {
    "id": 18,
    "date": "17 ноября 1980 года",
    "title": "Первая партия на экспорт",
    "description": "17 ноября 1980 года была отгружена первая партия лёгких металлоконструкций на экспорт. Завод получил ответственный заказ на возведение металлургического комбината в Нигерии.",
    "icon": "work",
    "buttonText": "",
    "imageUrl": ""
  },
  {
    "id": 19,
    "date": "2 ноября 1987 года",
    "title": "Назначение Гончукова Владимира Петровича",
    "description": "На должность директора завода был назначен Гончуков Владимир Петрович. Завод осваивает выпуск новой продукции, включая оконные переплеты, зенитные фонари, ворота различных типоразмеров, торговые киоски и трёхслойные стеновые панели.",
    "icon": "work",
    "buttonText": "",
    "imageUrl": ""
  },
  {
    "id": 20,
    "date": "1986 год",
    "title": "Высокая культура производства",
    "description": "Коллегией Министерства монтажных и специальных строительных работ СССР и Президиума ЦК профсоюза рабочих строительства и промышленности строительных материалов заводу присвоено звание «Предприятие высокой культуры». Заводу вручено Переходное Красное Знамя с занесением на Всесоюзную Доску почёта ВДНХ СССР.",
    "icon": "work",
    "buttonText": "",
    "imageUrl": ""
  },
  {
    "id": 21,
    "date": "1987 год",
    "title": "Диплом за активное участие",
    "description": "Организационный комитет и дирекция Советского раздела наградили коллектив завода дипломом за активное участие в подготовке и проведении международной выставки «Стройиндустрия-87».",
    "icon": "work",
    "buttonText": "",
    "imageUrl": ""
  },
  {
    "id": 22,
    "date": "Разные годы",
    "title": "Заслуги работников и директоров",
    "description": "Все успехи коллектива - заслуга работников завода и ветеранов труда: газорезчиков Тряпичного Г.А., Ганецкой В.Ф., Владыки К.Е., Соломки Ю.И., термиста Янцевича Н.К., крановщицы Бесораб Е., электрообмотчиков Бурачонок И.И. и Бурачонок М.И., инженер-конструктора Сопача Н.И. Заводом руководили Калинин Семен Кузьмич, Сусленков Юрий Федорович, Линкин Дмитрий Николаевич, Самарин Станислав Иванович, Гончуков Владимир Петрович, Чурсин Николай Устимович, Купцевич Григорий Васильевич, Ангерчик Михаил Александрович.",
    "icon": "work",
    "buttonText": "",
    "imageUrl": ""
  }
];

const DetailedHistory = () => {
  return (
    <div className={style.timeline}>
      <h1 className={style.title}>История Завода</h1>
      <VerticalTimeline lineColor={'#193880'}>
        {timelineElements.map((element) => {
          let showButton =
            element.buttonText !== undefined &&
            element.buttonText !== null &&
            element.buttonText !== '';

          return (
            <VerticalTimelineElement
              contentStyle={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                background: '#fff',
                color: '#193880',
                borderTop: '3px solid #193880',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              }}
              contentArrowStyle={{ borderRight: '7px solid  #193880' }}
              iconStyle={{
                background: '#fff',
                color: '#193880',
              }}
              key={element.id}
              dateClassName="date"
              date={element.date}
              iconClassName={'icon'}
              icon={
                <Image_1
                  style={{
                    width: '60px',
                    height: '60px',
                    left: '20%',
                    top: '20%',
                  }}
                />
              }
            >
              <h3 className="vertical-timeline-element-title">
                {element.title}
              </h3>
              <h5 className="vertical-timeline-element-subtitle">
                {element.location}
              </h5>
              <p id="description">{element.description}</p>
              {showButton && (
                <a className={style.button} href="/about">
                  {element.buttonText}
                </a>
              )}
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
    </div>
  );
};

export default DetailedHistory;
