import React from 'react';
import style from './Logo.module.scss';
import { ReactComponent as MzmkIcon } from 'src/assets/icons/Mzmk_logotype.svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Logo = () => {
  const { t } = useTranslation();
  return (
    <Link className={style.logo} to="/">
      <MzmkIcon role="img" aria-label="MZMK icon" className={style.logoIcon} />
      <span className={style['logoText']}>{t('logo.company_name')}</span>
      <span className={style['logoTextAlt']}>{t('logo.company_name_alt')}</span>
    </Link>
  );
};

export default Logo;
