import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import style from './Breadcrumbs.module.scss';
import { useTranslation } from 'react-i18next';

const Breadcrumbs: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  const breadcrumbNameMap: { [key: string]: string } = {
    home: t('breadcrumbs.home'),
    search: t('breadcrumbs.search'),
    catalog: t('breadcrumbs.catalog'),
    'metal-structures': t('breadcrumbs.metal_structures'),
    towers: t('breadcrumbs.towers'),
    'bridges-and-bridge-sections': t('breadcrumbs.bridges_and_bridge_sections'),
    'bunker-ramps': t('breadcrumbs.bunker_ramps'),
    'custom-metal-structures': t('breadcrumbs.custom_metal_structures'),
    'metal-processing': t('breadcrumbs.metal_processing'),
    'metal-preparation': t('breadcrumbs.metal_preparation'),
    'protective-paint-coatings': t('breadcrumbs.protective_paint_coatings'),
    // 'greenhouses': t('breadcrumbs.greenhouses'),
    // 'custom-metal-products': t('breadcrumbs.custom_metal_products'),
    projects: t('breadcrumbs.projects'),
    about: t('breadcrumbs.about'),
    certificates: t('breadcrumbs.certificates'),
    'financial-report': t('breadcrumbs.financial_report'),
    'anti-corruption': t('breadcrumbs.anti_corruption'),
    appeals: t('breadcrumbs.appeals'),
    contacts: t('breadcrumbs.contacts'),
  };

  const getBreadcrumbs = () => {
    const breadcrumbs = [];
    let currentPath = '';

    breadcrumbs.push(
      <li key="/" className={style.breadcrumbItem}>
        <Link to="/">{t('breadcrumbs.home')}</Link>
      </li>
    );

    if (pathnames.includes('products')) {
      currentPath = '/products';
      breadcrumbs.push(
        <li key={currentPath} className={style.breadcrumbItem}>
          <Link to={currentPath}>{t('breadcrumbs.catalog')}</Link>
        </li>
      );
    }
    if (pathnames.includes('about')) {
      currentPath = '/about';
      breadcrumbs.push(
        <li key={currentPath} className={style.breadcrumbItem}>
          <Link to={currentPath}>{t('breadcrumbs.about')}</Link>
        </li>
      );
    }

    pathnames.forEach((value) => {
      if (value !== 'catalog' && value !== 'about') {
        currentPath += `/${value}`;
        const name = breadcrumbNameMap[value];

        if (name) {
          breadcrumbs.push(
            <li key={currentPath} className={style.breadcrumbItem}>
              <span>{name}</span>
            </li>
          );
        }
      }
    });

    return breadcrumbs;
  };

  return (
    <nav className={style.breadcrumbs}>
      <ul className={style.breadcrumbList}>
        {getBreadcrumbs().map((breadcrumb, index) => (
          <React.Fragment key={index}>
            {breadcrumb}
            {index < getBreadcrumbs().length - 1 && (
              <span className={style.separator}> / </span>
            )}
          </React.Fragment>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
