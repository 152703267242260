import React from 'react';
import PageTemplate from '../../components/ModulesForPages/PageTemplate';
import Banner from '../../components/Elements/Banner';
import style from './ProductionPage.module.scss';
import Catalog from '../../components/Modules/Catalog';
import Tabs from 'src/components/Modules/Tabs';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const ProductionPage = () => {
  const { t } = useTranslation();

  const productionTabs = [
    {
      id: '1',
      label: t('ourProducts.products'),
      content: <Catalog type="product" />,
    },
    {
      id: '2',
      label: t('ourProducts.services'),
      content: <Catalog type="service" />,
    },
  ];

  return (
    <PageTemplate>
      <Helmet>
        <title>
          {t('breadcrumbs.catalog')} | {t('bannerTitles.companyName')}
        </title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Официальный сайт ОАО «Молодечненский завод металлоконструкций»."
        />
        <link rel="canonical" href="https://mzmk.by/products" />
      </Helmet>
      <Banner titleKey="products" />
      <div className={style.productPage}>
        <div className={style.container}>
          <Tabs tabs={productionTabs} namespace="productionTabs" />
        </div>
      </div>
    </PageTemplate>
  );
};

export default ProductionPage;
