import React, { FC, useState } from 'react';
import style from './Tabs.module.scss';
import { RootState } from 'src/app/store';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveTab } from 'src/features/tabs/tabsSlice';

interface ITab {
  id: string;
  label: string;
  content: React.ReactNode;
}

interface ITabsProps {
  tabs: ITab[];
  namespace: string;
}

const Tabs: FC<ITabsProps> = ({ tabs, namespace }) => {
  const dispatch = useDispatch();
  const activeTab = useSelector(
    (state: RootState) => state.tabs[namespace]?.activeTabId || tabs[0].id
  );

  const handleTabClick = (tabId: string) => {
    dispatch(setActiveTab({ namespace, tabId }));
  };

  // const [activeTab, setActiveTab] = useState(tabs[0].id);

  const renderContent = () => {
    const activeTabContent = tabs.find((tab) => tab.id === activeTab);
    return activeTabContent ? activeTabContent.content : null;
  };

  return (
    <>
      <nav className={style.tabs}>
        <div className={style.tabButtons}>
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`${style.tabButton} ${
                activeTab === tab.id ? `${style.active}` : ''
              }`}
              onClick={() => handleTabClick(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div>
      </nav>
      <div className={style.tabContent}>{renderContent()}</div>
    </>
  );
};

export default Tabs;
