import React from 'react';
import { ReactComponent as LinkIcon } from 'src/assets/icons/link.svg';
import style from './A11yCertificates.module.scss';
import { selectAccessibility } from 'src/features/accessibility/accessibilitySlice';
import { useSelector } from 'react-redux';
import { getCertificatesData } from 'src/data/certificatesData';
import { useTranslation } from 'react-i18next';

const A11yCertificates = () => {
  const { t } = useTranslation();
  const { colorScheme } = useSelector(selectAccessibility);
  const certificatesData = getCertificatesData(t);

  return (
    <div className={style.a11yCertificates}>
      <h2>{t('certificates.title')}</h2>
      <p>{t('certificates.content.0')}</p>
      <ul>
        <li>{t('certificates.content.1')}</li>
        <li>{t('certificates.content.2')}</li>
        <p></p>
      </ul>
      <p>{t('certificates.content.3')}</p>
      <p>{t('certificates.content.4')}</p>
      <p>{t('certificates.content.5')}</p>

      <ul className={style.certificateList}>
        {certificatesData.map((cert, index) => (
          <li className={style.certificateItem}>
            <a href={cert.src} target="_blank" rel="noopener noreferrer">
              <LinkIcon
                role="img"
                aria-label="link icon"
                className={`${style.linkIcon} ${colorScheme}-scheme-icon`}
              />
              <span className={style.title}>{cert.title}</span>
              <span className={style.params}>
                <span>PDF</span>
                {cert.size}
              </span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default A11yCertificates;
