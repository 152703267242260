import React, { FC } from 'react';
import A11yFooter from '../A11yFooter';
import A11yHeader from '../A11yHeader';
import A11yMenu from '../A11yMenu';
import style from './A11yPageTemplate.module.scss';
import A11ySideBar from '../../A11yModules/A11ySideBar';
import { useSelector } from 'react-redux';
import {
  setColorScheme,
  selectAccessibility,
} from 'src/features/accessibility/accessibilitySlice';

type IA11yPageTemplateProps = {
  children: React.ReactNode;
};

const A11yPageTemplate: FC<IA11yPageTemplateProps> = ({ children }) => {
  const { colorScheme } = useSelector(selectAccessibility);
  return (
    <div className={`${style.a11yWrapper} ${colorScheme}-scheme`}>
      <div className={style.a11yPageTemplate}>
        <A11yMenu />
        <A11yHeader />
        <main>
          <A11ySideBar />
          {children}
        </main>
        <A11yFooter />
      </div>
    </div>
  );
};

export default A11yPageTemplate;
