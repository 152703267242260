import React from 'react';
import style from './A11yAntiCorruptionPage.module.scss';
import A11yPageTemplate from 'src/components/Accessibility/A11yModulesForPages/A11yPageTemplate';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const A11yAntiCorruptionPage = () => {
  const { t } = useTranslation();

  return (
    <A11yPageTemplate>
      <Helmet>
        <title>
          {t('breadcrumbs.anti_corruption')} | {t('bannerTitles.companyName')}
        </title>
        <meta name="robots" content="noindex, nofollow" />
        <meta
          name="description"
          content="Страница доступности ОАО «Молодечненский завод металлоконструкций» для пользователей с нарушением зрения. Настройки шрифтов и цветовых схем для удобства просмотра."
        />
      </Helmet>
      <h1>{t('breadcrumbs.anti_corruption')}</h1>
      <div className={style.a11yAntiCorruptionPage}>
        <p>{t('antiCorruptionPage.Intro')}</p>
        <p>{t('antiCorruptionPage.Notification')}</p>
        <ul>
          <li>
            <p>{t('antiCorruptionPage.CrisisManagerContact')}</p>
          </li>
          <ul>
            <li>
              <p>
                <a href={`tel:+375172701313`}>+375(17) 270-13-13</a>
              </p>
            </li>
            <li>
              <p>
                <a href={`tel:+375291671313`}>+375(29) 167-13-13</a>
              </p>
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            <p>{t('antiCorruptionPage.EngineerContact')}</p>
            <ul>
              <li>
                <p>
                    <a href={`tel:+375257180502`}>+375(25) 718-05-02</a>
                </p>
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li>
            {' '}
            <p>{t('antiCorruptionPage.ReceptionContacts')}</p>
          </li>
          <ul>
            <li>
                <p>
                    <a href={`tel:+375176770402`}>+375(17) 677-04-02</a>
                </p>
            </li>
            <li>
                <p>
                    <a href={`tel:+375257180535`}>+375(25) 718-05-35</a>
                </p>
            </li>
          </ul>
        </ul>
        <p>{t('antiCorruptionPage.LegalFramework')}</p>
        <ul className={style.legalFrameworkList}>
          <li className={style.legalFrameworkItem}>
            <a
              href="/documents/Anti_Corruption_Law.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('antiCorruptionPage.documents.LawOnCorruption')}
            </a>
          </li>
          <li className={style.legalFrameworkItem}>
            <a
              href="/documents/Convention_On_Corruption.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('antiCorruptionPage.documents.PresidentialDecree')}
            </a>
          </li>
          <li className={style.legalFrameworkItem}>
            <a
              href="/documents/Convention_UN.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('antiCorruptionPage.documents.CriminalCode')}
            </a>
          </li>
          <li className={style.legalFrameworkItem}>
            <a
              href="/documents/Convention_Civil_Liability.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('antiCorruptionPage.documents.AdministrativeOffensesCode')}
            </a>
          </li>
          <li className={style.legalFrameworkItem}>
            <a
              href="/documents/Contents_Page_Anti-Corruption.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('antiCorruptionPage.documents.LaborCode')}
            </a>
          </li>
          <li className={style.legalFrameworkItem}>
            <a
              href="/documents/Contents_Page_Anti-Corruption.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('antiCorruptionPage.documents.PublicServiceLaw')}
            </a>
          </li>
          <li className={style.legalFrameworkItem}>
            <a
              href="/documents/Contents_Page_Anti-Corruption.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('antiCorruptionPage.documents.PreventionLaw')}
            </a>
          </li>
          <li className={style.legalFrameworkItem}>
            <a
              href="/documents/Contents_Page_Anti-Corruption.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('antiCorruptionPage.documents.AdministrativeProceduresLaw')}
            </a>
          </li>
          <li className={style.legalFrameworkItem}>
            <a
              href="/documents/Contents_Page_Anti-Corruption.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('antiCorruptionPage.documents.PublicProcurementLaw')}
            </a>
          </li>
          <li className={style.legalFrameworkItem}>
            <a
              href="/documents/Contents_Page_Anti-Corruption.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('antiCorruptionPage.documents.AntiCorruptionCommissionDecree')}
            </a>
          </li>
          <li className={style.legalFrameworkItem}>
            <a
              href="/documents/Contents_Page_Anti-Corruption.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('antiCorruptionPage.documents.IncomeDeclarationDecree')}
            </a>
          </li>
        </ul>
      </div>
    </A11yPageTemplate>
  );
};

export default A11yAntiCorruptionPage;
