import React, { FC, useRef }  from 'react';
import style from './PageTemplate.module.scss';
import Footer from '../Footer';
import Header from '../Header';
import CallToAction from '../../Modules/CallToAction';
import SideBar from '../../Modules/SideBar';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';

type IPageTemplateProps = {
  children: React.ReactNode;
};

const PageTemplate: FC<IPageTemplateProps> = ({ children }) => {
  const isSidebarOpen = useSelector((state: RootState) => state.sidebar.isOpen);
  const burgerMenuRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Header burgerMenuRef={burgerMenuRef}/>
      <SideBar burgerMenuRef={burgerMenuRef}/>
      <main
        className={`${
          isSidebarOpen ? style.mainExpanded : style.mainCollapsed
        }`}
      >
        {children}
      </main>
      {/* <CallToAction isSidebarOpen={isSidebarOpen} /> */}
      <Footer isSidebarOpen={isSidebarOpen} />
    </>
  );
};

export default PageTemplate;
