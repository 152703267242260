import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';

type AccessibilityState = {
  fontSize: string;
  colorScheme: 'white' | 'black' | 'blue' | 'brown';
  fontType: 'sans-serif' | 'serif';
  letterSpacing: 'normal' | '2px' | '6px';
  isSettingsOpen: boolean;
  isLanguageOpen: boolean;
  isSidebarOpen: boolean;
};

const initialState: AccessibilityState = {
  fontSize: '16px',
  colorScheme: 'white',
  fontType: 'sans-serif',
  letterSpacing: 'normal',
  isSettingsOpen: false,
  isLanguageOpen: false,
  isSidebarOpen: false,
};

const accessibilitySlice = createSlice({
  name: 'accessibility',
  initialState,
  reducers: {
    setFontSize(state, action: PayloadAction<string>) {
      state.fontSize = action.payload;
    },
    setColorScheme(
      state,
      action: PayloadAction<'white' | 'black' | 'blue' | 'brown'>
    ) {
      state.colorScheme = action.payload;
    },
    setFontType: (state, action: PayloadAction<'sans-serif' | 'serif'>) => {
      state.fontType = action.payload;
    },
    setLetterSpacing: (
      state,
      action: PayloadAction<'normal' | '2px' | '6px'>
    ) => {
      state.letterSpacing = action.payload;
    },
    toggleSettings: (state) => {
      state.isSettingsOpen = !state.isSettingsOpen;
      if (state.isSettingsOpen) {
        state.isLanguageOpen = false;
        state.isSidebarOpen = false;
      }
    },
    toggleLanguage: (state) => {
      state.isLanguageOpen = !state.isLanguageOpen;
      if (state.isLanguageOpen) state.isSettingsOpen = false;
    },
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
      if (state.isSidebarOpen) {
        state.isSettingsOpen = false;
      }
    },
    resetSettings: () => initialState,
  },
});

export const {
  setFontSize,
  setColorScheme,
  setFontType,
  setLetterSpacing,
  toggleSettings,
  toggleLanguage,
  toggleSidebar,
  resetSettings,
} = accessibilitySlice.actions;

export const selectAccessibility = (state: RootState) => state.accessibility;

export default accessibilitySlice.reducer;
