import React, { useRef, useState } from 'react';
import slide_1_desktop from 'src/assets/client-images/Slider/slide_1_desktop.jpg';
import slide_1_mobile from 'src/assets/client-images/Slider/slide_1_mobile.jpg';
import slide_2_desktop from 'src/assets/client-images/Slider/slide_2_desktop.jpg';
import slide_2_mobile from 'src/assets/client-images/Slider/slide_2_mobile.jpg';
import desktop_1 from 'src/assets/client-images/Slider/desktop_slide_1.webp';
import desktop_2 from 'src/assets/client-images/Slider/desktop_slide_2.webp';

import 'swiper/scss';
import 'swiper/scss/effect-fade';
import 'swiper/scss/autoplay';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import './Slider.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';



const SimpleSlider = () => {
  return (
    <div className="sliderContainer">
      <Swiper
        spaceBetween={30}
        effect={'fade'}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        speed={1500}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, EffectFade, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={desktop_1} className="desktop-img" />
          <img src={slide_1_mobile} className="mobile-img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={desktop_2} className="desktop-img" />
          <img src={slide_2_mobile} className="mobile-img" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default SimpleSlider;
