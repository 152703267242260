import React, { useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import NotFoundPage from './pages/NotFoundPage';
import HomePage from './pages/HomePage';
import ProductionPage from './pages/ProductionPage';
import AboutPage from './pages/AboutPage';
import AppealsPage from './pages/AppealsPage';
import ProjectsPage from './pages/ProjectsPage';
import ContactsPage from './pages/ContactsPage';
import AntiCorruptionPage from './pages/AntiCorruptionPage';
import MetalStructures from './components/ModulesForPages/Products/MetalStructures';
import Towers from './components/ModulesForPages/Products/Towers';
import CustomMetalProducts from './components/ModulesForPages/Products/CustomMetalProducts';
import ScrollToTop from './components/Modules/ScrollToTop';
import AccessibilityPage from './pages/AccessibilityPage';
import BridgesAndBridgeSections from './components/ModulesForPages/Products/BridgesAndBridgeSections';
import BunkerRamps from './components/ModulesForPages/Products/BunkerRamps';
import MetalProcessing from './components/ModulesForPages/Products/MetalProcessing';
import MetalPreparation from './components/ModulesForPages/Products/MetalPreparation';
import ProtectivePaintCoatings from './components/ModulesForPages/Products/ProtectivePaintCoatings';
import style from './App.module.scss';
import SearchPage from './pages/SearchPage';
import A11ySearchPage from './pages/A11ySearchPage';
import A11yContactsPage from './pages/A11yContactsPage';
import A11yAppealsPage from './pages/A11yAppealsPage';
import A11yProductsPage from './pages/A11yProductsPage';
import A11yAboutPage from './pages/A11yAboutPage';
import A11yAntiCorruptionPage from './pages/A11yAntiCorruptionPage';
import A11yMetalStructures from './components/Accessibility/A11yModulesForPages/A11yProducts/A11yMetalStructures';
import A11yTowers from './components/Accessibility/A11yModulesForPages/A11yProducts/A11yTowers';
import A11yBridgesAndBridgeSections from './components/Accessibility/A11yModulesForPages/A11yProducts/A11yBridgesAndBridgeSections';
import A11yBunkerRamps from './components/Accessibility/A11yModulesForPages/A11yProducts/A11yBunkerRamps';
import A11yCustomMetalProducts from './components/Accessibility/A11yModulesForPages/A11yProducts/A11yCustomMetalProducts';
import A11yMetalProcessing from './components/Accessibility/A11yModulesForPages/A11yProducts/A11yMetalProcessing';
import A11yMetalPreparation from './components/Accessibility/A11yModulesForPages/A11yProducts/A11yMetalPreparation';
import A11yProtectivePaintCoatings from './components/Accessibility/A11yModulesForPages/A11yProducts/A11yProtectivePaintCoatings';

function App() {
// react helmet

  return (
    <div className={style.wrapper}>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/products" element={<ProductionPage />} />
        <Route path="/products/metal-structures" element={<MetalStructures />} />
        <Route path="/products/towers" element={<Towers />} />
        <Route path="/products/bridges-and-bridge-sections" element={<BridgesAndBridgeSections />} />
        <Route path="/products/bunker-ramps" element={<BunkerRamps />} />
        <Route path="/products/custom-metal-structures" element={<CustomMetalProducts />} />
        <Route path="/products/metal-processing" element={<MetalProcessing />} />
        <Route path="/products/metal-preparation" element={<MetalPreparation />} />
        <Route path="/products/protective-paint-coatings" element={<ProtectivePaintCoatings />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/anti-corruption" element={<AntiCorruptionPage />} />
        <Route path="/appeals" element={<AppealsPage />} />
        <Route path="/contacts" element={<ContactsPage />} />
        <Route path="/accessibility" element={<AccessibilityPage />} />
        <Route path="/accessibility/search" element={<A11ySearchPage />} />
        <Route path="/accessibility/products" element={<A11yProductsPage/>} />
        <Route path="/accessibility/products/metal-structures" element={<A11yMetalStructures />} />
        <Route path="/accessibility/products/towers" element={<A11yTowers />} />
        <Route path="/accessibility/products/bridges-and-bridge-sections" element={<A11yBridgesAndBridgeSections />} />
        <Route path="/accessibility/products/bunker-ramps" element={<A11yBunkerRamps />} />
        <Route path="/accessibility/products/custom-metal-structures" element={<A11yCustomMetalProducts />} />
        <Route path="/accessibility/products/metal-processing" element={<A11yMetalProcessing />} />
        <Route path="/accessibility/products/metal-preparation" element={<A11yMetalPreparation />} />
        <Route path="/accessibility/products/protective-paint-coatings" element={<A11yProtectivePaintCoatings />} /> 
        <Route path="/accessibility/about" element={<A11yAboutPage />} />
        <Route path="/accessibility/anti-corruption" element={<A11yAntiCorruptionPage />} />
        <Route path="/accessibility/appeals" element={<A11yAppealsPage />} />
        <Route path="/accessibility/contacts" element={<A11yContactsPage />} />
        <Route path="/404" element={<NotFoundPage />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
        {/* <Route path="*" element={<NotFoundPage />} /> */}
      </Routes>
    </div>
  );
}

export default App;
