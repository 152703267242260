import React, { forwardRef, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { toggleSidebar } from 'src/features/sidebar/sidebarSlice';
import { ReactComponent as MenuIcon } from 'src/assets/icons/sideBarIcons/burgerMenu.svg';
import style from './MenuButton.module.scss';

const MenuButton = forwardRef((props, ref: React.Ref<HTMLButtonElement>) => {
  const dispatch = useDispatch();

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(toggleSidebar());
  };
  
  return (
    <button ref={ref} onClick={handleClick} className={style.iconBtn}>
      <MenuIcon role="img" aria-label="menu icon" className={style.icon} />
    </button>
  );
});

export default MenuButton;
