import React, { FC } from 'react';
import style from './Banner.module.scss';
import Breadcrumbs from '../Breadcrumbs';
import { useTranslation } from 'react-i18next';

interface IBannerProps {
  titleKey: string;
}

const Banner: FC<IBannerProps> = ({titleKey}) => {
  const { t } = useTranslation();

  return (
    <div className={style.banner}>
      <div className={style.container}>
        <h1 className={style.title}>{t(`bannerTitles.${titleKey}`)}</h1>
        <Breadcrumbs/>
      </div>
    </div>
  );
};

export default Banner;
