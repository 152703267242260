import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as SearchIcon } from 'src/assets/icons/search.svg';
import { ReactComponent as SettingIcon } from 'src/assets/icons/settings_fill.svg';
import { ReactComponent as ArrowDropDown } from 'src/assets/icons/arrows/arrow_drop_down.svg';
import { ReactComponent as BurgerMenuIcon } from 'src/assets/icons/menu.svg';
import style from './A11yMenu.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFontSize,
  setColorScheme,
  setFontType,
  setLetterSpacing,
  toggleSettings,
  toggleLanguage,
  toggleSidebar,
  resetSettings,
  selectAccessibility,
} from 'src/features/accessibility/accessibilitySlice';
import { RootState } from 'src/app/store';
import A11yLanguageSwitcher from '../../A11yElement/A11yLanguageSwitcher';
import FontSizeSelector from '../../A11yElement/FontSizeSelector';
import A11ySettingsDropdown from '../../A11yElement/A11ySettingsDropdown';

const accessiblePaths = [
  '/accessibility',
  '/accessibility/products',
  '/accessibility/products/metal-structures',
  '/accessibility/products/towers',
  '/accessibility/products/bridges-and-bridge-sections',
  '/accessibility/products/bunker-ramps',
  '/accessibility/products/custom-metal-structures',
  '/accessibility/products/metal-processing',
  '/accessibility/products/metal-preparation',
  '/accessibility/products/protective-paint-coatings',
  '/accessibility/about',
  '/accessibility/anti-corruption',
  '/accessibility/appeals',
  '/accessibility/contacts',
  '/accessibility/search',
];

const A11yMenu = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    fontSize,
    fontType,
    letterSpacing,
    colorScheme,
    isSettingsOpen,
  } = useSelector(selectAccessibility);

  const handleResetSettings = () => {
    dispatch(resetSettings());
    dispatch(toggleSettings());
  };

  const handleFontSizeChange = useCallback((size: string) => {
    dispatch(setFontSize(size));
  }, [dispatch]);

  const handleColorSchemeChange = useCallback(
    (scheme: 'white' | 'black' | 'blue' | 'brown') => {
    dispatch(setColorScheme(scheme));
  },
  [dispatch]
  );

  useEffect(() => {
    document.documentElement.style.fontFamily =
      fontType === 'sans-serif'
        ? 'Montserrat, sans-serif'
        : 'Georgia, Times, Times New Roman, serif';
  }, [fontType]);

  useEffect(() => {
    document.documentElement.style.letterSpacing =
      letterSpacing === 'normal'
        ? 'normal'
        : letterSpacing === '2px'
        ? '2px'
        : '6px';
  }, [letterSpacing]);

  useEffect(() => {
    document.documentElement.style.fontSize =
    accessiblePaths.includes(location.pathname) ? fontSize : '16px';
  }, [fontSize, location.pathname]);

  return (
    <nav className={`${style.a11yMenu} ${colorScheme}-scheme-reverse`}>
      <div className={style.a11yMenuContainer}>
      {/* {isSettingsOpen && (
        <A11ySettingsDropdown 
          fontSize={fontSize}
          fontType={fontType}
          letterSpacing={letterSpacing}
          colorScheme={colorScheme}
          onFontSizeChange={(size: any) => dispatch(setFontSize(size))}
          onFontTypeChange={(type: any) => dispatch(setFontType(type))}
          onLetterSpacingChange={(spacing: any) => dispatch(setLetterSpacing(spacing))}
          onColorSchemeChange={(scheme: any) => dispatch(setColorScheme(scheme))}
          onResetSettings={() => dispatch(resetSettings())}
        />
      )} */}
        {isSettingsOpen && (
          <div className={`${style.dropDownMenu} ${colorScheme}-scheme`}>
            <div className={style.menuSection}>
              <h3>Размер шрифта:</h3>
              <div className={style.fontSizeButtons}>
                <button
                  className={`${style.fontSizeButton} ${
                    fontSize === '16px'
                      ? `${colorScheme}-scheme-reverse`
                      : `${colorScheme}-scheme`
                  }`}
                  onClick={() => handleFontSizeChange('16px')}
                  style={{ fontSize: '20px' }}
                >
                  Обычный
                </button>
                <button
                  className={`${style.fontSizeButton} ${
                    fontSize === '20px'
                      ? `${colorScheme}-scheme-reverse`
                      : `${colorScheme}-scheme`
                  }`}
                  onClick={() => handleFontSizeChange('20px')}
                  style={{ fontSize: '24px' }}
                >
                  Средний
                </button>
                <button
                  className={`${style.fontSizeButton} ${
                    fontSize === '24px'
                      ? `${colorScheme}-scheme-reverse`
                      : `${colorScheme}-scheme`
                  }`}
                  onClick={() => handleFontSizeChange('24px')}
                  style={{ fontSize: '28px' }}
                >
                  Большой
                </button>
              </div>
            </div>
            <div className={style.menuSection}>
              <h3>Шрифт:</h3>
              <div className={style.typeFontButtons}>
                <button
                  className={`${style.typeFontButton} ${
                    fontType === 'sans-serif'
                      ? `${colorScheme}-scheme-reverse`
                      : `${colorScheme}-scheme`
                  }`}
                  onClick={() => dispatch(setFontType('sans-serif'))}
                >
                  Без засечек
                </button>
                <button
                  className={`${style.typeFontButton} ${
                    fontType === 'serif'
                      ? `${colorScheme}-scheme-reverse`
                      : `${colorScheme}-scheme`
                  }`}
                  onClick={() => dispatch(setFontType('serif'))}
                >
                  С засечками
                </button>
              </div>
            </div>
            <div className={style.menuSection}>
              <h3>Межбуквенное расстояние:</h3>
              <div className={style.letterSpacing}>
                <button
                  className={`${style.letterSpacingButton} ${
                    letterSpacing === 'normal'
                      ? `${colorScheme}-scheme-reverse`
                      : `${colorScheme}-scheme`
                  }`}
                  onClick={() => dispatch(setLetterSpacing('normal'))}
                >
                  Стандартное
                </button>
                <button
                  className={`${style.letterSpacingButton} ${
                    letterSpacing === '2px'
                      ? `${colorScheme}-scheme-reverse`
                      : `${colorScheme}-scheme`
                  }`}
                  onClick={() => dispatch(setLetterSpacing('2px'))}
                >
                  Увеличенное
                </button>
                <button
                  className={`${style.letterSpacingButton} ${
                    letterSpacing === '6px'
                      ? `${colorScheme}-scheme-reverse`
                      : `${colorScheme}-scheme`
                  }`}
                  onClick={() => dispatch(setLetterSpacing('6px'))}
                >
                  Большое
                </button>
              </div>
            </div>
            <div className={style.menuSection}>
              <h3>Цветовая схема:</h3>
              <div className={style.colorSchemeButtons}>
                <button
                  className={`${style.colorSchemeButton} ${style.white}`}
                  onClick={() => handleColorSchemeChange('white')}
                >
                  Чёрным по белому
                </button>
                <button
                  className={`${style.colorSchemeButton} ${style.black}`}
                  onClick={() => handleColorSchemeChange('black')}
                >
                  Белым по чёрному
                </button>
                <button
                  className={`${style.colorSchemeButton} ${style.blue}`}
                  onClick={() => handleColorSchemeChange('blue')}
                >
                  Тёмно-синим по голубому
                </button>
                <button
                  className={`${style.colorSchemeButton} ${style.brown}`}
                  onClick={() => handleColorSchemeChange('brown')}
                >
                  Коричневым по бежевому
                </button>
              </div>
            </div>
            <div className={style.menuActions}>
              <button
                className={`${style.menuActionsButton} ${colorScheme}-scheme-reverse`}
                onClick={handleResetSettings}
              >
                Стандартные настройки
              </button>
              <button
                className={`${style.menuActionsButton} ${colorScheme}-scheme-reverse`}
                onClick={() => dispatch(toggleSettings())}
              >
                Закрыть панель
              </button>
            </div>
          </div>
        )}
        <div className={style.a11yMenuItem}>
          <button
            className={style.burgerMenuButton}
            onClick={() => dispatch(toggleSidebar())}
          >
            <BurgerMenuIcon
              className={`${
                style.icon
              } ${`${colorScheme}-scheme-icon-reverse`}`}
            />
            <span>Меню</span>
          </button>
        </div>
        <div className={style.a11yMenuItem}>
          <span>Размер шрифта: </span>
          <div className={style.fontSizeButtons}>
            <button
              className={`${style.fontSizeButton} ${
                fontSize === '16px' ? style.active : ''
              }`}
              onClick={() => handleFontSizeChange('16px')}
              style={{ fontSize: '20px' }}
            >
              A
            </button>
            <button
              className={`${style.fontSizeButton} ${
                fontSize === '20px' ? style.active : ''
              }`}
              onClick={() => handleFontSizeChange('20px')}
              style={{ fontSize: '24px' }}
            >
              A
            </button>
            <button
              className={`${style.fontSizeButton} ${
                fontSize === '24px' ? style.active : ''
              }`}
              onClick={() => handleFontSizeChange('24px')}
              style={{ fontSize: '28px' }}
            >
              A
            </button>
          </div>
        </div>
        <div className={style.a11yMenuItem}>
          <span>Цвет сайта:</span>
          <div className={style.colorSchemeButtons}>
            <button
              className={`${style.colorSchemeButton} ${style.white}`}
              onClick={() => handleColorSchemeChange('white')}
            >
              Ц
            </button>
            <button
              className={`${style.colorSchemeButton} ${style.black}`}
              onClick={() => handleColorSchemeChange('black')}
            >
              Ц
            </button>
            <button
              className={`${style.colorSchemeButton} ${style.blue}`}
              onClick={() => handleColorSchemeChange('blue')}
            >
              Ц
            </button>
            <button
              className={`${style.colorSchemeButton} ${style.brown}`}
              onClick={() => handleColorSchemeChange('brown')}
            >
              Ц
            </button>
          </div>
        </div>
        <div className={style.a11yMenuItem}>
          <div
            className={`${style.settingsSwitcher} ${
              isSettingsOpen ? `${colorScheme}-scheme` : ''
            }`}
          >
            <button
              className={style.settingsButton}
              onClick={() => dispatch(toggleSettings())}
            >
              <SettingIcon
                className={`${style.icon} ${
                  isSettingsOpen
                    ? `${colorScheme}-scheme-icon`
                    : `${colorScheme}-scheme-icon-reverse`
                }`}
              />
              {'Настройки'}&nbsp;
              <ArrowDropDown
                className={`${style.iconArrowDown} ${
                  isSettingsOpen
                    ? `${colorScheme}-scheme-icon`
                    : `${colorScheme}-scheme-icon-reverse`
                } ${isSettingsOpen ? style.open : ''}`}
              />
            </button>
          </div>
        </div>
        <div className={style.a11yMenuItem}>
          <A11yLanguageSwitcher />
        </div>
        <div className={style.a11yMenuItem}>
          <div className={style.search}>
            <Link to="/accessibility/search">
              <SearchIcon
                className={`${style.icon} ${colorScheme}-scheme-icon-reverse`}
              />
              <span>Поиск</span>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default A11yMenu;
