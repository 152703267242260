import React, { useState } from 'react';
import style from './A11ySearch.module.scss';
import Fuse from 'fuse.js';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface IA11yCatalogItem {
  alt: string;
  title: string;
  description: string;
  link: string;
}

const A11ySearch = () => {
  const { t, i18n } = useTranslation();
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<IA11yCatalogItem[]>([]);

  const A11yCatalogItems = [
    {
      alt: t('ourProducts.metal_structures_alt'),
      title: t('ourProducts.metal_structures'),
      description: t('ourProducts.metal_structures_desc'),
      link: '/accessibility/products/metal-structures',
    },
    {
      alt: t('ourProducts.towers_alt'),
      title: t('ourProducts.towers'),
      description: t('ourProducts.towers_desc'),
      link: '/accessibility/products/towers',
    },
    {
      alt: t('ourProducts.bridges_and_bridge_sections_alt'),
      title: t('ourProducts.bridges_and_bridge_sections'),
      description: t('ourProducts.bridges_and_bridge_sections_desc'),
      link: '/accessibility/products/bridges-and-bridge-sections',
    },
    {
      alt: t('ourProducts.bunker_ramps_alt'),
      title: t('ourProducts.bunker_ramps'),
      description: t('ourProducts.bunker_ramps_desc'),
      link: '/accessibility/products/bunker-ramps',
    },
    {
      alt: t('ourProducts.custom_metal_structures_alt'),
      title: t('ourProducts.custom_metal_structures'),
      description: t('ourProducts.custom_metal_structures_desc'),
      link: '/accessibility/products/custom-metal-structures',
    },
    {
      alt: t('ourProducts.metal_processing_alt'),
      title: t('ourProducts.metal_processing'),
      description: t('ourProducts.metal_processing_desc'),
      link: '/accessibility/products/metal-processing',
    },
    {
      alt: t('ourProducts.metal_preparation_alt'),
      title: t('ourProducts.metal_preparation'),
      description: t('ourProducts.metal_preparation_desc'),
      link: '/accessibility/products/metal-preparation',
    },
    {
      alt: t('ourProducts.protective_paint_coatings_alt'),
      title: t('ourProducts.protective_paint_coatings'),
      description: t('ourProducts.protective_paint_coatings_desc'),
      link: '/accessibility/products/protective-paint-coatings',
    },
  ];

  const fuse = new Fuse(A11yCatalogItems, {
    keys: ['title', 'description'],
    threshold: 0.3,
    includeScore: true,
  });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuery(value);

    if (value.length >= 2) {
      const result = fuse.search(value);
      setResults(result.map((res) => res.item));
    } else {
      setResults([]);
    }
  };

  return (
    <div className={style.A11ySearchContainer}>
        <h1>Поиск</h1>
      <input
        type="text"
        value={query}
        onChange={handleSearch}
        placeholder={t('search.placeholder')}
        className={style.searchInput}
      />
      <div className={style.searchResults}>
        {results.map((item, index) => (
          <Link to={item.link} key={index} className={style.resultItem}>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default A11ySearch;
