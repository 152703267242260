import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { slidePhotos } from 'src/data/slidePhotos';
import 'swiper/scss';
import 'swiper/scss/autoplay';
import 'swiper/scss/pagination';
import 'swiper/scss/navigation';
import './PhotoSlider.scss';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useTranslation } from 'react-i18next';

const PhotoSlider = () => {
  const { t } = useTranslation();
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const swiperRef = useRef<any>(null); // Use `any` for Swiper instance type

  const handleSwiper = (swiper: any) => {
    swiperRef.current = swiper; // Save Swiper instance
  };
  const handleSlideChange = () => {
    // Optional: You can do something on slide change
  };

  return (
    <section id="projects" className="section">
      <h2>{t('realizedProjects.title')}</h2>
      <Swiper
        preventInteractionOnTransition={true}
        spaceBetween={20}
        slidesPerView={'auto'}
        loop
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        speed={500}
        cssMode={false}
        navigation={true}
        pagination={{ clickable: true }}
        modules={[Autoplay, Pagination, Navigation]}
        breakpoints={{
          100: {
            speed: 200,
            centeredSlides: true,
            spaceBetween: 10,
          },
          768: {
            centeredSlides: false,
            spaceBetween: 20,
          },
        }}
        onSlideChange={handleSlideChange}
        onSwiper={handleSwiper}
      >
        {slidePhotos.map((slide, index) => (
          <SwiperSlide
            key={slide.id}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <img src={slide.image} alt="" />
            <div
              className={`slide-text ${
                hoveredIndex === index ? 'visible' : ''
              }`}
            >
              {slide.text}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default PhotoSlider;
