import React, { useState } from 'react';
import style from './A11yAboutPage.module.scss';
import A11yPageTemplate from 'src/components/Accessibility/A11yModulesForPages/A11yPageTemplate';
import A11yProductionCapacity from 'src/components/Accessibility/A11yModulesForPages/A11yAbout/A11yProductionCapacity';
import { useSelector } from 'react-redux';
import { selectAccessibility } from 'src/features/accessibility/accessibilitySlice';
import A11yManagement from 'src/components/Accessibility/A11yModules/A11yManagement';
import A11yCertificates from 'src/components/Accessibility/A11yModulesForPages/A11yAbout/A11yCertificates';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const A11yAboutPage = () => {
  const { t } = useTranslation();
  const { colorScheme } = useSelector(selectAccessibility);

  const aboutTabs = [
    {
      id: '1',
      label: t('about.production_facilities'),
      content: <A11yProductionCapacity />,
    },
    { id: '2', label: t('about.certificates'), content: <A11yCertificates /> },
    { id: '3', label: t('about.management'), content: <A11yManagement /> },
    { id: '4', label: t('about.history'), content: '' },
    // { id: '5', label: 'История2', content: '' },
  ];
  const [activeTab, setActiveTab] = useState(aboutTabs[0].id);

  const renderContent = () => {
    const activeTabContent = aboutTabs.find((tab) => tab.id === activeTab);
    return activeTabContent ? activeTabContent.content : null;
  };

  return (
    <A11yPageTemplate>
      <Helmet>
        <title>
          {t('breadcrumbs.about')} | {t('bannerTitles.companyName')}
        </title>
        <meta name="robots" content="noindex, nofollow" />
        <meta
          name="description"
          content="Страница доступности ОАО «Молодечненский завод металлоконструкций» для пользователей с нарушением зрения. Настройки шрифтов и цветовых схем для удобства просмотра."
        />
      </Helmet>
      <div className={style.a11yAboutPage}>
        <h1>{t('bannerTitles.about')}</h1>
        <>
          <nav className={`${style.tabs} ${colorScheme}-scheme`}>
            <div className={style.tabButtons}>
              {aboutTabs.map((tab) => (
                <button
                  key={tab.id}
                  className={`${style.tabButton} ${
                    activeTab === tab.id
                      ? `${colorScheme}-scheme-reverse`
                      : `${colorScheme}-scheme`
                  }`}
                  onClick={() => setActiveTab(tab.id)}
                >
                  {tab.label}
                </button>
              ))}
            </div>
          </nav>
          <div className={style.tabContent}>{renderContent()}</div>
        </>
      </div>
    </A11yPageTemplate>
  );
};

export default A11yAboutPage;
