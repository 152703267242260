import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TabsState {
  [namespace: string]: {
    activeTabId: string;
  };
}

const initialState: TabsState = {};

const tabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    setActiveTab(state, action: PayloadAction<{ namespace: string; tabId: string }>) {
      state[action.payload.namespace] = { activeTabId: action.payload.tabId };
    },
  },
});

export const { setActiveTab } = tabsSlice.actions;
export default tabsSlice.reducer;
