import React, { FC } from 'react';
import style from './Catalog.module.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { catalogItems } from 'src/data/catalogData';

interface ICatalogProps {
  type?: 'product' | 'service';
}

const Catalog: FC<ICatalogProps> = ({ type }) => {
  const { t } = useTranslation();
  const filteredItems = type ? catalogItems.filter(item => item.type === type) : catalogItems;

  return (
    <section className={style.catalogSection}>
      <div className={style.catalogContainer}>
        <h2>{t('ourProducts.title')}</h2>
        <div className={style.catalogGrid}>
          {filteredItems.map((item, index) => (
            <div key={index} className={style.catalogCard}>
              <div className={style.catalogCardContent}>
                <img src={item.img} alt={t(`ourProducts.${item.alt}`)} />
                <h3>{t(`ourProducts.${item.title}`)}</h3>
                {/* <p>{t(`ourProducts.${item.description}`)}</p> */}
              </div>
              <Link to={item.link} className={style.catalogCardLink}>
                {t('ourProducts.learn_more')}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Catalog;
