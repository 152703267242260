import React from 'react';
import PageTemplate from '../../PageTemplate';
import Banner from 'src/components/Elements/Banner';
import style from './ProtectivePaintCoatings.module.scss';
import PaintCoatingsImage from 'src/assets/client-images/Catalog/Application-of-Protective-Coatings.jpg';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const ProtectivePaintCoatings = () => {
  const { t } = useTranslation();

  return (
    <PageTemplate>
      <Helmet>
        <title>
          {t('breadcrumbs.protective_paint_coatings')} | {t('bannerTitles.companyName')}
        </title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Официальный сайт ОАО «Молодечненский завод металлоконструкций»."
        />
        <link rel="canonical" href="https://mzmk.by/products/protective-paint-coatings" />
      </Helmet>
      <Banner titleKey="protectivePaintCoatings" />
      <div className={style.paintCoatingsSection}>
        <div className={style.contentWrapper}>
          <div className={style.imageContainer}>
            <img src={PaintCoatingsImage} alt="Paint Coatings" />
          </div>
          <div className={style.textContent}>
            {t('ourProducts.protective_paint_coatings_desc')}
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default ProtectivePaintCoatings;
