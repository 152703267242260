import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as MzmkIcon } from 'src/assets/icons/Mzmk_logotype.svg';
import style from './A11yHeader.module.scss';
import EyeButton from '../../A11yElement/EyeButton';

const A11yHeader = () => {
  const { t } = useTranslation();

  return (
    <header className={style.a11yHeader}>
      <nav>
        <Link to="/accessibility">
          <MzmkIcon
            role="img"
            aria-label="MZMK icon"
            className={style.logoIcon}
          />
          <span className={style['logoText']}>{t('logo.company_name')}</span>
          <span className={style['logoTextAlt']}>
            {t('logo.company_name_alt')}
          </span>
        </Link>
        <EyeButton />
      </nav>
    </header>
  );
};

export default A11yHeader;
