import React from 'react';
import style from './FooterGovLinks.module.scss';
import gerb_img from 'src/assets/icons/Gerb.png';
import pravoBy_img from 'src/assets/icons/PravoBy.png';
import { useTranslation } from 'react-i18next';

const FooterGovLinks = () => {
  const { t } = useTranslation();

  return (
    <div className={style.footerGovLinks}>
      <ul>
        <li>
          <a href="https://president.gov.by/ru">
            <img src={gerb_img} alt={t('Footer.president_site_alt')} />
            <span>{t('Footer.president_site')}</span>
          </a>
        </li>
        <li>
          <a href="https://pravo.by/">
            <img src={pravoBy_img} alt={t('Footer.legal_portal_alt')} />
            <span>{t('Footer.legal_portal')}</span>
          </a>
        </li>
        <li>
          <a href="http://mas.gov.by/ru">
            <img src={gerb_img} alt={t('Footer.architecture_ministry_alt')} />
            <span>{t('Footer.architecture_ministry')}</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FooterGovLinks;
