import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from './LanguageSwitcher.module.scss';
import { ReactComponent as ArrowDropDown } from 'src/assets/icons/arrows/arrow_drop_down.svg';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className={style.languageSwitcher} ref={menuRef}>
      <button
        className={style.switcherButton}
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
        aria-label="Select language"
      >
        {i18n.language === 'ru'
          ? 'РУС'
          : i18n.language === 'by'
          ? 'БЕЛ'
          : 'ENG'}
          <ArrowDropDown
          className={`${style.arrow} ${isOpen ? style.open : ''}`}
        />
      </button>
      <div className={`${style.dropdownMenu} ${isOpen ? style.show : ''}`}>
        <button
          className={`${style.dropdownItem} ${
            i18n.language === 'ru' ? style.none : ''
          }`}
          onClick={() => changeLanguage('ru')}
          aria-label="Switch to Russian"
        >
          РУС
        </button>
        <button
          className={`${style.dropdownItem} ${
            i18n.language === 'by' ? style.none : ''
          }`}
          onClick={() => changeLanguage('by')}
          aria-label="Switch to Belorussian"
        >
          БЕЛ
        </button>
        <button
          className={`${style.dropdownItem} ${
            i18n.language === 'en' ? style.none : ''
          }`}
          onClick={() => changeLanguage('en')}
          aria-label="Switch to English"
        >
          ENG
        </button>
      </div>
    </div>
  );
};

export default LanguageSwitcher;
