import React from 'react';
import PageTemplate from '../../PageTemplate';
import Banner from '../../../Elements/Banner';
import style from './CustomMetalProducts.module.scss';
import CustomMetalImage from 'src/assets/client-images/Catalog/Custom-Manufacturing-of-Metal-Structures.jpg';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const CustomMetalProducts = () => {
  const { t } = useTranslation();

  return (
    <PageTemplate>
      <Helmet>
        <title>
          {t('breadcrumbs.custom_metal_structures')} | {t('bannerTitles.companyName')}
        </title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Официальный сайт ОАО «Молодечненский завод металлоконструкций»."
        />
        <link rel="canonical" href="https://mzmk.by/products/custom-metal-structures" />
      </Helmet>
      <Banner titleKey="customMetalProducts" />
      <div className={style.CustomMetalSection}>
        <div className={style.contentWrapper}>
          <div className={style.imageContainer}>
            <img src={CustomMetalImage} alt="metal structures" />
          </div>
          <div className={style.textContent}>
            {t('ourProducts.custom_metal_structures_desc')}
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default CustomMetalProducts;
