import React, { useEffect, useState } from 'react';
import style from './A11ySideBar.module.scss';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  setColorScheme,
  toggleSidebar,
  selectAccessibility,
} from 'src/features/accessibility/accessibilitySlice';
import A11yLanguageSwitcher from '../../A11yElement/A11yLanguageSwitcher';

const A11ySideBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { colorScheme, isSidebarOpen } = useSelector(selectAccessibility);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  const location = useLocation();
  // const [activeTab, setActiveTab] = useState({
  //   products: 'products',
  //   about: 'power',
  // });

  // const handleTabClick = (section: any, tab: any) => {
  //   setActiveTab(prevState => ({
  //     ...prevState,
  //     [section]: tab,
  //   }));
  // };

  // const renderMegaMenu = () => {
  //   switch (location.pathname) {
  //     case '/accessibility/products':
  //       return (
  //         <ul className={style.megaMenuList}>
  //           <li
  //             className={`${style.megaMenuItem} ${activeTab.products === 'products' ? `${colorScheme}-scheme-reverse` : ''}`}
  //             onClick={() => handleTabClick('products', 'products')}
  //           >
  //             {t('ourProducts.products')}
  //           </li>
  //           <li
  //             className={`${style.megaMenuItem} ${activeTab.products === 'services' ? `${colorScheme}-scheme-reverse` : ''}`}
  //             onClick={() => handleTabClick('products', 'services')}
  //           >
  //             {t('ourProducts.services')}
  //           </li>
  //         </ul>
  //       );
  //     case '/accessibility/about':
  //       return (
  //         <ul className={style.megaMenuList}>
  //           <li
  //             className={`${style.megaMenuItem} ${activeTab.about === 'power' ? `${colorScheme}-scheme-reverse` : ''}`}
  //             onClick={() => handleTabClick('about', 'power')}
  //           >
  //             Power
  //           </li>
  //           <li
  //             className={`${style.megaMenuItem} ${activeTab.about === 'certif' ? `${colorScheme}-scheme-reverse` : ''}`}
  //             onClick={() => handleTabClick('about', 'certif')}
  //           >
  //             Certif
  //           </li>
  //           <li
  //             className={`${style.megaMenuItem} ${activeTab.about === '123' ? `${colorScheme}-scheme-reverse` : ''}`}
  //             onClick={() => handleTabClick('about', '123')}
  //           >
  //             123
  //           </li>
  //           <li
  //             className={`${style.megaMenuItem} ${activeTab.about === 'history' ? `${colorScheme}-scheme-reverse` : ''}`}
  //             onClick={() => handleTabClick('about', 'history')}
  //           >
  //             History
  //           </li>
  //         </ul>
  //       );
  //     default:
  //       return null;
  //   }
  // };

  const handleLinkClick = () => {
    window.scrollTo({ top: 0 });

    if (!isDesktop) {
      dispatch(toggleSidebar());
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {(isSidebarOpen || isDesktop) && (
        <nav className={`${style.a11ySideBar} ${colorScheme}-scheme`}>
          <ul className={style.sideBarList}>
            <>
              <li className={style.sideBarItem}>
                <NavLink
                  to="/accessibility"
                  end
                  className={({ isActive }) =>
                    isActive
                      ? `${style.link} ${colorScheme}-scheme-reverse`
                      : style.link
                  }
                  onClick={handleLinkClick}
                >
                  {t('sideBar.home')}
                </NavLink>
              </li>
              <li className={style.sideBarItem}>
                <NavLink
                  to="/accessibility/products"
                  className={({ isActive }) =>
                    isActive
                      ? `${style.link} ${colorScheme}-scheme-reverse`
                      : style.link
                  }
                  onClick={handleLinkClick}
                >
                  {t('sideBar.products')}
                </NavLink>
              </li>
              <li className={style.sideBarItem}>
                <NavLink
                  to="/accessibility/about"
                  className={({ isActive }) =>
                    isActive
                      ? `${style.link} ${colorScheme}-scheme-reverse`
                      : style.link
                  }
                  onClick={handleLinkClick}
                >
                  {t('sideBar.about')}
                </NavLink>
              </li>
              <li className={style.sideBarItem}>
                <NavLink
                  to="/accessibility/anti-corruption"
                  className={({ isActive }) =>
                    isActive
                      ? `${style.link} ${colorScheme}-scheme-reverse`
                      : style.link
                  }
                  onClick={handleLinkClick}
                >
                  {t('sideBar.antiCorruption')}
                </NavLink>
              </li>
              <li className={style.sideBarItem}>
                <NavLink
                  to="/accessibility/appeals"
                  className={({ isActive }) =>
                    isActive
                      ? `${style.link} ${colorScheme}-scheme-reverse`
                      : style.link
                  }
                  onClick={handleLinkClick}
                >
                  {t('sideBar.appeals')}
                </NavLink>
              </li>
              <li className={style.sideBarItem}>
                <NavLink
                  to="/accessibility/contacts"
                  className={({ isActive }) =>
                    isActive
                      ? `${style.link} ${colorScheme}-scheme-reverse`
                      : style.link
                  }
                  onClick={handleLinkClick}
                >
                  {t('sideBar.contacts')}
                </NavLink>
              </li>
            </>
            {!isDesktop && (
              <li>
                <A11yLanguageSwitcher onLinkClick={handleLinkClick} />
              </li>
            )}
          </ul>
          {/* {renderMegaMenu()} */}
        </nav>
      )}
    </>
  );
};

export default A11ySideBar;
