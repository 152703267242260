import React from 'react';
import PageTemplate from '../../components/ModulesForPages/PageTemplate';
import Banner from '../../components/Elements/Banner';
import style from './ContactsPage.module.scss';
import Management from 'src/components/ModulesForPages/About/Management/Management';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const ContactsPage = () => {
  const { t } = useTranslation();

  return (
    <PageTemplate>
      <Helmet>
        <title>{t('breadcrumbs.contacts')} | {t('bannerTitles.companyName')}</title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Официальный сайт ОАО «Молодечненский завод металлоконструкций»."
        />
        <link rel="canonical" href="https://mzmk.by/contacts" />
      </Helmet>
      <Banner titleKey="contacts" />

      <div className={style.contactsPage}>
        <div className={style.container}>
          <h2>{t('management.address')}</h2>
          <p>{t('Footer.address')}</p>
          <h2>{t('management.contact')}</h2>
          <p className={style.tel}>
            <a href="tel:+375176770402">+375 (17) 677-04-02</a>
            <span>({t('management.phones.office')})</span>
          </p>
          {/* <p className={style.tel}><a href="tel:+375176581437">+375 (17) 658-14-37</a><span>(факс)</span></p>
            <p className={style.tel}><a href="tel:+375176581455">+375 (17) 658-14-55</a><span>(бухгалтерия)</span></p>
            <p className={style.tel}><a href="tel:+375176541825">+375 (17) 654-18-25</a><span>(отдел снабжения)</span></p>
            <p ><a href="tel:+375176771953">+375 (17) 677-19-53</a><span>(отдел маркетинга и сбыта)</span></p> */}
          <h2>{t('management.email')}</h2>
          <p className={style.email}>
            <a href="mailto:rupzmk@yandex.by">rupzmk@yandex.by</a>
          </p>
          <p>
            <a href="mailto:rupzmk@gmail.com">rupzmk@gmail.com</a>
          </p>

          <Management />
        </div>
      </div>
    </PageTemplate>
  );
};

export default ContactsPage;
