import image_1 from 'src/assets/client-images/Objects/OldSite/National Library.jpg';
import image_2 from 'src/assets/client-images/Objects/OldSite/Multifunctional complex Baltic Pearl, St. Petersburg.jpg';
import image_3 from 'src/assets/client-images/Objects/OldSite/1 (1).jpg';
import image_4 from 'src/assets/client-images/Objects/OldSite/St_vit.jpg';
import image_5 from 'src/assets/client-images/Objects/OldSite/IMG_1804.jpg';
import image_6 from 'src/assets/client-images/Objects/OldSite/P4280005.jpg';
import image_7 from 'src/assets/client-images/Objects/OldSite/Base station of a mobile operator.jpg';
import image_8 from 'src/assets/client-images/Objects/OldSite/bash.jpg';
import image_9 from 'src/assets/client-images/Objects/OldSite/7_ru.jpg';
import image_10 from 'src/assets/client-images/Objects/OldSite/ikea_russia3.jpg';
import image_11 from 'src/assets/client-images/Objects/OldSite/10.jpg';
import image_12 from 'src/assets/client-images/Objects/OldSite/4_ru.jpg';
import image_13 from 'src/assets/client-images/Objects/OldSite/3_ru.jpg';
import image_14 from 'src/assets/client-images/Objects/OldSite/2_ru.jpg';
import image_15 from 'src/assets/client-images/Objects/OldSite/12.jpg';
import image_16 from 'src/assets/client-images/Objects/OldSite/Newspaper factory Asnova Shklov.jpg';
import image_17 from 'src/assets/client-images/Objects/OldSite/Belarusian cement plant, Krichev.jpg';
import image_18 from 'src/assets/client-images/Objects/OldSite/Slantsy cement plant CESLA Leningrad region.jpg';
import image_19 from 'src/assets/client-images/Objects/OldSite/1-l.jpg';
import image_20 from 'src/assets/client-images/Objects/OldSite/itz.jpg';
import image_21 from 'src/assets/client-images/Objects/OldSite/8_ru.jpg';
import image_22 from 'src/assets/client-images/Objects/OldSite/6_ru.jpg';
import image_23 from 'src/assets/client-images/Objects/OldSite/5_ru.jpg';
import image_24 from 'src/assets/client-images/Objects/OldSite/02.jpg';
import image_25 from 'src/assets/client-images/Objects/OldSite/01.jpg';
import image_26 from 'src/assets/client-images/Objects/OldSite/fer_07.jpg';
import image_27 from 'src/assets/client-images/Objects/OldSite/fer_04.jpg';
import image_28 from 'src/assets/client-images/Objects/OldSite/fer_08.jpg';
import image_29 from 'src/assets/client-images/Objects/OldSite/spk.jpg';
import image_30 from 'src/assets/client-images/Objects/OldSite/fer_05.jpg';
import image_31 from 'src/assets/client-images/Objects/OldSite/fer_01.jpg';
import image_32 from 'src/assets/client-images/Objects/OldSite/Bobruisk Arena.jpg';
import image_33 from 'src/assets/client-images/Objects/OldSite/Ice Palace Baranovichi.jpg';
import image_34 from 'src/assets/client-images/Objects/OldSite/Sports and entertainment complex in Soligorsk.jpg';
import image_35 from 'src/assets/client-images/Objects/OldSite/9.jpg';
import image_36 from 'src/assets/client-images/Objects/OldSite/The main concert venue of the international arts festival Slavianski Bazaar in Vitebsk.jpg';
import image_37 from 'src/assets/client-images/Objects/OldSite/6.jpg';
import image_38 from 'src/assets/client-images/Objects/OldSite/5.jpg';
import image_39 from 'src/assets/client-images/Objects/OldSite/4_1.jpg';
import image_40 from 'src/assets/client-images/Objects/OldSite/Football arena in Minsk.jpg';
import image_41 from 'src/assets/client-images/Objects/OldSite/Borisov Arena.jpg';
import image_42 from 'src/assets/client-images/Objects/OldSite/Chizhovka Arena, Minsk.jpg';
import image_43 from 'src/assets/client-images/Objects/OldSite/15-1.jpg';
import image_44 from 'src/assets/client-images/Objects/OldSite/Sports and entertainment center of Molodechno.jpg';
import image_45 from 'src/assets/client-images/Objects/OldSite/Minsk Arena.jpg';
import image_46 from 'src/assets/client-images/Objects/OldSite/19.jpg';
import image_47 from 'src/assets/client-images/Objects/OldSite/9_ru.jpg';
import image_48 from 'src/assets/client-images/Objects/OldSite/181.jpg';
import image_49 from 'src/assets/client-images/Objects/OldSite/52_full.jpg';
import image_50 from 'src/assets/client-images/Objects/OldSite/16.jpg';

interface ICard {
  id: number;
  image: string;
  title: string;
  text: string;
  category: string;
}

export const cardData: ICard[] = [
  {
    id: 1,
    image: `${image_1}`,
    title: 'Национальная библиотека',
    text: 'Национальная библиотека РБ, г. Минск',
    category: 'administrative',
  },
  {
    id: 2,
    image: `${image_2}`,
    title: 'Коммерческий центр',
    text: 'Многофункциональный коммерческий центр комплекса «Балтийская жемчужина»',
    category: 'administrative',
  },
  {
    id: 3,
    image: `${image_3}`,
    title: 'Автовокзал',
    text: 'Автовокзал “Московский”. г. Минск',
    category: 'administrative',
  },
  {
    id: 4,
    image: `${image_4}`,
    title: 'Башня осветительная',
    text: 'Башня осветительная стадиона, г. Витебск',
    category: 'towers',
  },
  {
    id: 5,
    image: `${image_5}`,
    title: 'Башня мобильной телефонной связи',
    text: 'Башня мобильной телефонной связи',
    category: 'towers',
  },
  {
    id: 6,
    image: `${image_6}`,
    title: 'Башня мобильной телефонной связи',
    text: 'Башня мобильной телефонной связи',
    category: 'towers',
  },
  {
    id: 7,
    image: `${image_7}`,
    title: 'Телевизионный передатчик',
    text: 'Телевизионный передатчик , г. Свислочь',
    category: 'towers',
  },
  {
    id: 8,
    image: `${image_8}`,
    title: 'Башня мобильной телефонной связи',
    text: 'Башня мобильной телефонной связи',
    category: 'towers',
  },
  {
    id: 9,
    image: `${image_9}`,
    title: 'Морские радиомаяки',
    text: 'Морские радиомаяки г. Санкт-Петербург',
    category: 'towers',
  },
  {
    id: 10,
    image: `${image_10}`,
    title: 'Торговый центр IKEA',
    text: 'Торговый центр IKEA, Россия',
    category: 'logistics_and_trade',
  },
  {
    id: 11,
    image: `${image_11}`,
    title: 'Логистический центр',
    text: 'Логистический центр «Евразия логистик», Северное Домодедово, Московская область',
    category: 'logistics_and_trade',
  },
  {
    id: 12,
    image: `${image_12}`,
    title: 'Торгово-развлекательный комплекс',
    text: 'Универсальный торгово-развлекательный комплекс «Мега-центр» г. Калининград',
    category: 'logistics_and_trade',
  },
  {
    id: 13,
    image: `${image_13}`,
    title: 'Автоцентр «Lexus»',
    text: 'Автоцентр «Lexus», г. Москва',
    category: 'logistics_and_trade',
  },
  {
    id: 14,
    image: `${image_14}`,
    title: 'Торговый центр',
    text: 'Торговый ценр «Вестер» г. Калининград',
    category: 'logistics_and_trade',
  },
  {
    id: 15,
    image: `${image_15}`,
    title: 'Трубопрокатный комплекс',
    text: 'Трубопрокатный комплекс РУП «Белорусского металлургического завода» в г.Жлобине',
    category: 'industrial_buildings',
  },
  {
    id: 16,
    image: `${image_16}`,
    title: 'Завод по производству газетной бумаги',
    text: 'Завод по производству газетной бумаги с лесопильным производством в г. Шклове',
    category: 'industrial_buildings',
  },
  {
    id: 17,
    image: `${image_17}`,
    title: 'Белорусский цементный завод',
    text: 'Белорусский цементный завод',
    category: 'industrial_buildings',
  },
  {
    id: 18,
    image: `${image_18}`,
    title: 'Сланцевский цементный завод',
    text: 'ОАО «Сланцевский цементный завод «ЦЕСЛА»',
    category: 'industrial_buildings',
  },
  {
    id: 19,
    image: `${image_19}`,
    title: 'Цех по окраске кораблей',
    text: 'Цех по очистке и окраске кораблей г. Клайпеда',
    category: 'industrial_buildings',
  },
  {
    id: 20,
    image: `${image_20}`,
    title: 'Ижорский трубный завод',
    text: 'Ижорский трубный завод, г.Санкт-Петербург',
    category: 'industrial_buildings',
  },
  {
    id: 21,
    image: `${image_21}`,
    title: 'Перегрузочный комплекс',
    text: 'Перегрузочный комплекс минудобрений г. Санкт-Петербург',
    category: 'industrial_buildings',
  },
  {
    id: 22,
    image: `${image_22}`,
    title: 'Завод переработки нефти',
    text: 'Завод глубокой переработки нефти , г. Кириш',
    category: 'industrial_buildings',
  },
  {
    id: 23,
    image: `${image_23}`,
    title: 'Павильон ТНПА',
    text: 'Павильон ТНПА на ВДНХ, г. Москва',
    category: 'industrial_buildings',
  },
  {
    id: 24,
    image: `${image_24}`,
    title: 'Строительство МТФ',
    text: 'Строительство МТФ на 1000 голов с расширением водозабора в д. Кривичи Солигорский р-н СПК «Большевик-Агро»',
    category: 'agriculture',
  },
  {
    id: 25,
    image: `${image_25}`,
    title: 'Ферма «Макаши»',
    text: 'Строительство МТФ на 420 голов, СПК «Несвижские островки», Несвижский р-н',
    category: 'agriculture',
  },
  {
    id: 26,
    image: `${image_26}`,
    title: 'Молочно-товарная ферма',
    text: 'Строительство МТФ на 600 голов, СПК «Чурлены», Вилейский р-н',
    category: 'agriculture',
  },
  {
    id: 27,
    image: `${image_27}`,
    title: 'Молочно-товарная ферма',
    text: 'Строительство МТФ на 600 голов, п. Хотюхово, Крупский р-н',
    category: 'agriculture',
  },
  {
    id: 28,
    image: `${image_28}`,
    title: 'Молочно-товарная ферма',
    text: 'Строительство МТФ на 1000 голов, д. Толковичи, Дзержинский р-н',
    category: 'agriculture',
  },
  {
    id: 29,
    image: `${image_29}`,
    title: 'Овощехранилище',
    text: 'СПК «Гигант»: овощехранилище на 20000 тонн',
    category: 'agriculture',
  },
  {
    id: 30,
    image: `${image_30}`,
    title: 'Молочно-товарная ферма',
    text: 'Строительство МТФ на 850 голов, д .Орехово УКСП «Совхоз Доброволец», Кличевский р-н',
    category: 'agriculture',
  },
  {
    id: 31,
    image: `${image_31}`,
    title: 'Молочно-товарная ферма',
    text: 'Строительство МТФ на 800 голов, д.М.Несталовичи Логойского р-на',
    category: 'agriculture',
  },
  {
    id: 32,
    image: `${image_32}`,
    title: 'Ледовый дворец',
    text: 'Ледовый дворец на 7000 мест в г.Бобруйске',
    category: 'sports_and_entertainment',
  },
  {
    id: 33,
    image: `${image_33}`,
    title: 'Ледовый дворец',
    text: 'Ледовый дворец на 2000 мест с залом акробатики по пр.Советский в г.Барановичи',
    category: 'sports_and_entertainment',
  },
  {
    id: 34,
    image: `${image_34}`,
    title: 'Спортивно-зрелищный комплекс',
    text: 'Спортивно-зрелищный комплекс с спортивным залом в г.Солигорске',
    category: 'sports_and_entertainment',
  },
  {
    id: 35,
    image: `${image_35}`,
    title: 'Мобильный сезонный каток',
    text: 'Мобильный сезонный каток по ул. Победителей г.Минск',
    category: 'sports_and_entertainment',
  },
  {
    id: 36,
    image: `${image_36}`,
    title: 'Реконструкция летнего амфитеатра',
    text: 'Реконструкция летнего амфитеатра «Славянский базар» в г.Витебске',
    category: 'sports_and_entertainment',
  },
  {
    id: 37,
    image: `${image_37}`,
    title: 'Ледовый дворец',
    text: 'Ледовый дворец, г. Могилев',
    category: 'sports_and_entertainment',
  },
  {
    id: 38,
    image: `${image_38}`,
    title: 'Ледовый дворец',
    text: 'Ледовый дворец, г. Минск, улица Притыцкого',
    category: 'sports_and_entertainment',
  },
  {
    id: 39,
    image: `${image_39}`,
    title: 'Ледовый дворец',
    text: 'Ледовый дворец, г. Минск, проспект Победителей',
    category: 'sports_and_entertainment',
  },
  {
    id: 40,
    image: `${image_40}`,
    title: 'Крытый футбольный манеж',
    text: 'Крытый футбольный манеж, г. Минск',
    category: 'sports_and_entertainment',
  },
  {
    id: 41,
    image: `${image_41}`,
    title: 'Борисов-Арена',
    text: 'Борисов-Арена',
    category: 'sports_and_entertainment',
  },
  {
    id: 42,
    image: `${image_42}`,
    title: 'МКРСК «Чижовка-Арена»',
    text: 'МКРСК «Чижовка-Арена», г. Минск',
    category: 'sports_and_entertainment',
  },
  {
    id: 43,
    image: `${image_43}`,
    title: 'Велодром',
    text: 'Велодром на 2000 мест. Минск — Арена',
    category: 'sports_and_entertainment',
  },
  {
    id: 44,
    image: `${image_44}`,
    title: 'Культурно-спортивный комплекс',
    text: 'Спортивно развлекательный центр, г. Молодечно',
    category: 'sports_and_entertainment',
  },
  {
    id: 45,
    image: `${image_45}`,
    title: 'Культурно-спортивный комплекс',
    text: 'Многофункциональный культурно-спортивный комплекс «Минск-Арена»',
    category: 'sports_and_entertainment',
  },
  {
    id: 46,
    image: `${image_46}`,
    title: 'Ледовый дворец',
    text: 'Ледовый дворец, г. Лида',
    category: 'sports_and_entertainment',
  },
  {
    id: 47,
    image: `${image_47}`,
    title: 'Крытый каток',
    text: 'Крытый каток г. Можайск (Россия)',
    category: 'sports_and_entertainment',
  },
  {
    id: 48,
    image: `${image_48}`,
    title: 'Реконструкция стадиона',
    text: 'Реконструкция стадиона г.Орша',
    category: 'sports_and_entertainment',
  },
  {
    id: 49,
    image: `${image_49}`,
    title: 'Физкультурно-оздоровительный центр',
    text: 'Физкультурно-оздоровительный центр с ледовым дворцом в г.Жлобине',
    category: 'sports_and_entertainment',
  },
  {
    id: 50,
    image: `${image_50}`,
    title: 'Конькобежный стадион',
    text: 'Конькобежный стадион на 3000 мест. Минск-Арена',
    category: 'sports_and_entertainment',
  },
];
