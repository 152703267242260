import React from 'react';
import Banner from 'src/components/Elements/Banner';
import PageTemplate from '../../PageTemplate';
import style from './MetalProcessing.module.scss';
import MetalProcessingImage from 'src/assets/client-images/Catalog/Processing-of-Metal-Rolling-and-Blanks.jpg';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const MetalProcessing = () => {
  const { t } = useTranslation();

  return (
    <PageTemplate>
      <Helmet>
        <title>
          {t('breadcrumbs.metal_processing')} | {t('bannerTitles.companyName')}
        </title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Официальный сайт ОАО «Молодечненский завод металлоконструкций»."
        />
        <link rel="canonical" href="https://mzmk.by/products/metal-processing" />
      </Helmet>
      <Banner titleKey="metalProcessing" />
      <div className={style.metalProcessingSection}>
        <div className={style.contentWrapper}>
          <div className={style.imageContainer}>
            <img src={MetalProcessingImage} alt="metal Processing" />
          </div>
          <div className={style.textContent}>
            {t('ourProducts.metal_processing_desc')}
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default MetalProcessing;
