import React, { useEffect, useState } from 'react';
import style from './ProjectsPage.module.scss';
import PageTemplate from '../../components/ModulesForPages/PageTemplate';
import Card from 'src/components/Elements/Card';
import Banner from 'src/components/Elements/Banner';
import { cardData } from 'src/data/cardData';
import FilterMenu from 'src/components/Elements/FilterMenu';
import LoadMoreButton from 'src/components/Elements/LoadMoreButton';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const ProjectsPage = () => {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [visibleCards, setVisibleCards] = useState(9);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedCategory(t('realizedProjects.categories.all'));
  }, [t]);

  const categories = [
    t('realizedProjects.categories.all'),
    t('realizedProjects.categories.administrative'),
    t('realizedProjects.categories.towers'),
    t('realizedProjects.categories.logistics_and_trade'),
    t('realizedProjects.categories.industrial_buildings'),
    t('realizedProjects.categories.agriculture'),
    t('realizedProjects.categories.sports_and_entertainment'),
  ];

  const filteredCards =
    selectedCategory === t('realizedProjects.categories.all')
      ? cardData
      : cardData.filter(
          (card) =>
            t(`realizedProjects.categories.${card.category}`) ===
            selectedCategory
        );

  useEffect(() => {
    setVisibleCards(9);
    setHasMore(true);
  }, [selectedCategory]);

  const handleLoadMore = () => {
    setIsLoading(true);
    setTimeout(() => {
      const newVisibleCount = visibleCards + 9;
      if (newVisibleCount >= filteredCards.length) {
        setHasMore(false);
      }
      setVisibleCards(newVisibleCount);
      setIsLoading(false);
    }, 1000);
  };

  return (
    <PageTemplate>
      <Helmet>
        <title>
          {t('breadcrumbs.projects')} | {t('bannerTitles.companyName')}
        </title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Официальный сайт ОАО «Молодечненский завод металлоконструкций»."
        />
        <link rel="canonical" href="https://mzmk.by/projects" />
      </Helmet>
      <Banner titleKey="projects" />
      <div className={style.projectsPage}>
        <div className={style.container}>
          <FilterMenu
            categories={categories}
            onCategorySelect={setSelectedCategory}
            selectedCategory={selectedCategory}
          />

          <div className={style.cardsContainer}>
            {filteredCards.slice(0, visibleCards).map((card, index) => (
              <Card
                key={index}
                image={card.image}
                title={card.title}
                text={card.text}
              />
            ))}
          </div>

          <LoadMoreButton onClick={handleLoadMore} isLoading={isLoading} />
        </div>
      </div>
    </PageTemplate>
  );
};

export default ProjectsPage;
