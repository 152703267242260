import React from 'react';
import style from './A11yWhyUs.module.scss';
import { useTranslation } from 'react-i18next';

const A11yWhyUs = () => {
  const { t } = useTranslation();

  return (
    <section className={style.a11yWhyUs}>
      <div className={style.container}>
        <h2>{t('whyUs.title')}</h2>
        <p>{t('whyUs.company_history')}</p>
        <div className={style.cards}>
          <div className={style.card}>
            <h3>51</h3>
            <p>{t('whyUs.years_on_market')}</p>
          </div>
          <div className={style.card}>
            <h3>60</h3>
            <p>{t('whyUs.awards')}</p>
          </div>
          <div className={style.card}>
            <h3>&gt;3000</h3>
            <p>{t('whyUs.clients')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default A11yWhyUs;
