import React from 'react';
import { useTranslation } from 'react-i18next';
import A11yPageTemplate from 'src/components/Accessibility/A11yModulesForPages/A11yPageTemplate';
import A11yCatalog from 'src/components/Accessibility/A11yModules/A11yCatalog';
import A11yWhyUs from 'src/components/Accessibility/A11yModules/A11yWhyUs';
import A11yHistory from 'src/components/Accessibility/A11yModules/A11yHistory';
import style from './AccessibilityPage.module.scss';
import { Helmet } from 'react-helmet-async';

const AccessibilityPage = () => {
  const { t } = useTranslation();

  return (
    <A11yPageTemplate>
      <Helmet>
        <title>
          {t('breadcrumbs.home')} | {t('bannerTitles.companyName')}
        </title>
        <meta name="robots" content="noindex, nofollow" />
        <meta
          name="description"
          content="Страница доступности ОАО «Молодечненский завод металлоконструкций» для пользователей с нарушением зрения. Настройки шрифтов и цветовых схем для удобства просмотра."
        />
      </Helmet>
      <h1>{t('sideBar.home')}</h1>
      <A11yCatalog />
      <A11yWhyUs />
      <A11yHistory />
    </A11yPageTemplate>
  );
};

export default AccessibilityPage;
