import React from 'react';
import style from './A11yHistory.module.scss';
import { useTranslation } from 'react-i18next';

const A11yHistory = () => {
  const { t } = useTranslation();

  return (
    <section className={style.a11yHistory}>
      <div className={style.container}>
        <div className={style.historyContent}>
          <h2>{t('history.title')}</h2>
          <p>{t('history.company_history')}</p>
        </div>
      </div>
    </section>
  );
};

export default A11yHistory;
