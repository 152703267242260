import React, { FC } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import style from './NavItem.module.scss'
import { Link } from 'react-router-dom';

interface INavItemProps {
    to: string;
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    label: string;
    onClick: () => void;
}

const NavItem: FC<INavItemProps> = ({ to, Icon, label, onClick }) => {
    const { t } = useTranslation();
    const isSidebarOpen = useSelector((state: RootState) => state.sidebar.isOpen);
  
    return (
      <li className={`${style.navItem} ${isSidebarOpen ? style.open : style.closed}`}>
        <Link to={to} className={isSidebarOpen ? style.open : style.closed} onClick={onClick}>
          <Icon role="img" aria-label={label} className={style.icon} />
          <span>{t(label)}</span>
        </Link>
      </li>
    );
}

export default NavItem