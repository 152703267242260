import React from 'react';
import PageTemplate from '../../components/ModulesForPages/PageTemplate';
import Banner from '../../components/Elements/Banner';
import style from './AntiCorruptionPage.module.scss';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const AntiCorruptionPage = () => {
  const { t } = useTranslation();

  return (
    <PageTemplate>
      <Helmet>
        <title>
          {t('breadcrumbs.anti_corruption')} | {t('bannerTitles.companyName')}
        </title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Официальный сайт ОАО «Молодечненский завод металлоконструкций»."
        />
        <link rel="canonical" href="https://mzmk.by/anti-corruption" />
      </Helmet>
      <Banner titleKey="antiCorruption" />
      <div className={style.antiCorruptionPage}>
        <div className={style.container}>
          <p>{t('antiCorruptionPage.Intro')}</p>
          <p>{t('antiCorruptionPage.Notification')}</p>
          <div>
            <h3>{t('management.yandovsky.name')}</h3>
            <h4>{t('antiCorruptionPage.CrisisManagerContact')}</h4>
            <h5>{t('management.contact')}</h5>
            <ul>
              <li>
                <a href={`tel:+375172701313`}>+375(17) 270-13-13</a>
              </li>
              <li>
                <a href={`tel:+375291671313`}>+375(29) 167-13-13</a>
              </li>
            </ul>
          </div>
          <div>
            <h3>{t('management.kupriyanov.name')}</h3>
            <h4>{t('management.kupriyanov.position')}</h4>
            <h5>{t('management.contact')}</h5>
            <ul>
              <li>
                <a href={`tel:+375257180502`}>+375(25) 718-05-02</a>
              </li>
            </ul>
          </div>
          <div>
            <h3>{t('antiCorruptionPage.ReceptionContacts')}</h3>
            <h5>{t('management.contact')}</h5>
            <ul>
              <li>
                <a href={`tel:+375176770402`}>+375(17) 677-04-02</a>
              </li>
              <li>
                <a href={`tel:+375257180535`}>+375(25) 718-05-35</a>
              </li>
            </ul>
          </div>
          <h3>{t('antiCorruptionPage.LegalFramework')}</h3>
          <ul>
            <li>
              <a
                href="/documents/LegalFramework/Anti_Corruption_Law.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('antiCorruptionPage.documents.LawOnCorruption')}
              </a>
            </li>
            <li>
              <a
                href="/documents/LegalFramework/PresidentialDecree.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('antiCorruptionPage.documents.PresidentialDecree')}
              </a>
            </li>
            <li>
              <a
                href="/documents/LegalFramework/CriminalCode.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('antiCorruptionPage.documents.CriminalCode')}
              </a>
            </li>
            <li>
              <a
                href="/documents/LegalFramework/AdministrativeOffensesCode.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('antiCorruptionPage.documents.AdministrativeOffensesCode')}
              </a>
            </li>
            <li>
              <a
                href="/documents/LegalFramework/LaborCode.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('antiCorruptionPage.documents.LaborCode')}
              </a>
            </li>
            <li>
              <a
                href="/documents/LegalFramework/PublicServiceLaw.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('antiCorruptionPage.documents.PublicServiceLaw')}
              </a>
            </li>
            <li>
              <a
                href="/documents/LegalFramework/PreventionLaw.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('antiCorruptionPage.documents.PreventionLaw')}
              </a>
            </li>
            <li>
              <a
                href="/documents/LegalFramework/AdministrativeProceduresLaw.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('antiCorruptionPage.documents.AdministrativeProceduresLaw')}
              </a>
            </li>
            <li>
              <a
                href="/documents/LegalFramework/PublicProcurementLaw.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('antiCorruptionPage.documents.PublicProcurementLaw')}
              </a>
            </li>
            <li>
              <a
                href="/documents/LegalFramework/AntiCorruptionCommissionDecree.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t(
                  'antiCorruptionPage.documents.AntiCorruptionCommissionDecree'
                )}
              </a>
            </li>
            <li>
              <a
                href="/documents/LegalFramework/IncomeDeclarationDecree.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('antiCorruptionPage.documents.IncomeDeclarationDecree')}
              </a>
            </li>
            <li>
              <a
                href="/documents/LegalFramework/AntiCorruptionPlan2024.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('antiCorruptionPage.documents.AntiCorruptionPlan2024')}
              </a>
            </li>
            <li>
              <a
                href="/documents/LegalFramework/AntiCorruptionCommissionRegulations.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t(
                  'antiCorruptionPage.documents.AntiCorruptionCommissionRegulations'
                )}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </PageTemplate>
  );
};

export default AntiCorruptionPage;
