import React from 'react';
import style from './HolidayVideoBlog.module.scss';
import { useTranslation } from 'react-i18next';

const HolidayVideoBlog = () => {
  const { t } = useTranslation();

  return (
    <section className={style.holidayVideoBlog}>
      <div className={style.container}>
        <div className={style.content}>
          <div className={style.videoContainer}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/t7Zf4yTorr4?si=2oqQLo9mrGKu4Pi3"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
          <div>
            <h3 className={style.title}>{t('unityDay.title')}</h3>
            <p className={style.text}>{t('unityDay.text')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HolidayVideoBlog;
