import React from 'react';
import style from './A11yManagement.module.scss';
import { getManagementData } from 'src/data/managementData';
import { useTranslation } from 'react-i18next';

const A11yManagement = () => {
  const { t } = useTranslation();
  const managementData = getManagementData(t);

  return (
    <div className={style.a11yManagement}>
      <h2>{t('management.title')}</h2>
      {managementData.map((person, index) => (
        <div key={index} className={style.card}>
          <h3>{person.name}</h3>
          <p>{person.position}</p>
          <p>{t('management.contact')}</p>
          <ul className={style.phones}>
            {person.phones.map((phone, idx) => (
              <li key={idx}>
                <p>
                  <a href={`tel:${phone}`}>{phone.number}</a>
                  {phone.description && <span>({phone.description})</span>}
                </p>
              </li>
            ))}
          </ul>
          {person.appointment ? <p>{t('management.schedule')}</p> : ''}
          {person.appointment && <p>{person.appointment}</p>}
        </div>
      ))}
    </div>
  );
};

export default A11yManagement;
