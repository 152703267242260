import React from 'react';
import PageTemplate from '../../PageTemplate';
import Banner from 'src/components/Elements/Banner';
import style from './BunkerRamps.module.scss';
import BunkerImage from 'src/assets/client-images/Catalog/Bunker-Ramps.jpg';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const BunkerRamps = () => {
  const { t } = useTranslation();

  return (
    <PageTemplate>
      <Helmet>
        <title>
          {t('breadcrumbs.bunker_ramps')} | {t('bannerTitles.companyName')}
        </title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Официальный сайт ОАО «Молодечненский завод металлоконструкций»."
        />
        <link rel="canonical" href="https://mzmk.by/products/bunker-ramps" />
      </Helmet>
      <Banner titleKey="bunkerRamps" />
      <div className={style.bunkerSection}>
        <div className={style.contentWrapper}>
          <div className={style.imageContainer}>
            <img src={BunkerImage} alt="Tower" />
          </div>
          <div className={style.textContent}>
            {t('ourProducts.bunker_ramps_desc')}
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default BunkerRamps;
