import React from 'react';
import MenuButton from 'src/components/Elements/MenuButton';
import Logo from 'src/components/Elements/Logo';
import HeaderControls from 'src/components/Elements/HeaderControls';
import style from './Header.module.scss';

const Header = ({ burgerMenuRef }: { burgerMenuRef: React.RefObject<HTMLButtonElement> }) => {
  return (
    <>
      <header className={style.header}>
        <div className={style.container}>
          <MenuButton ref={burgerMenuRef} />
          <div className={style.right}>
            <Logo />
            <div className={style.hideHc}>
              <HeaderControls />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
