import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Banner from 'src/components/Elements/Banner';
import Search from 'src/components/Elements/Search';
import PageTemplate from 'src/components/ModulesForPages/PageTemplate';

const SearchPage = () => {
  const { t } = useTranslation();

  return (
    <PageTemplate>
      <Helmet>
        <title>
          {t('breadcrumbs.search')} | {t('bannerTitles.companyName')}
        </title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Официальный сайт ОАО «Молодечненский завод металлоконструкций»."
        />
        <link rel="canonical" href="https://mzmk.by/search" />
      </Helmet>
      <Banner titleKey="search" />
      <Search />
    </PageTemplate>
  );
};

export default SearchPage;
