import React, { useEffect, useRef } from 'react';
import style from './SideBar.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { ReactComponent as HomeIcon } from 'src/assets/icons/sideBarIcons/home_24dp_193880_FILL1_wght400_GRAD0_opsz24.svg';
import { ReactComponent as ProductsIcon } from 'src/assets/icons/sideBarIcons/package_2_24dp_193880_FILL1_wght400_GRAD0_opsz24.svg';
import { ReactComponent as ProjectsIcon } from 'src/assets/icons/sideBarIcons/apartment_24dp_193880_FILL1_wght400_GRAD0_opsz24.svg';
import { ReactComponent as AboutIcon } from 'src/assets/icons/sideBarIcons/info_24dp_193880_FILL1_wght400_GRAD0_opsz24.svg';
import { ReactComponent as AntiCorruptionIcon } from 'src/assets/icons/sideBarIcons/gavel_24dp_193880_FILL1_wght400_GRAD0_opsz24.svg';
import { ReactComponent as AppealsIcon } from 'src/assets/icons/sideBarIcons/contact_mail_24dp_193880_FILL1_wght400_GRAD0_opsz24.svg';
import { ReactComponent as ContactsIcon } from 'src/assets/icons/sideBarIcons/call_24dp_193880_FILL1_wght400_GRAD0_opsz24.svg';
import { closeSidebar, toggleSidebar } from 'src/features/sidebar/sidebarSlice';
import NavItem from 'src/components/Elements/NavItem';
import HeaderControls from 'src/components/Elements/HeaderControls';

const SideBar = ({
  burgerMenuRef,
}: {
  burgerMenuRef: React.RefObject<HTMLButtonElement>;
}) => {
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector((state: RootState) => state.sidebar.isOpen);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const handleLinkClick = () => {
    if (
      window.innerWidth < 768 ||
      window.matchMedia('(max-width: 1000px) and (orientation: landscape)')
        .matches
    ) {
      dispatch(closeSidebar());
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target as Node) &&
      !(
        burgerMenuRef.current &&
        burgerMenuRef.current.contains(event.target as Node)
      ) &&
      (window.innerWidth < 768 ||
        window.matchMedia('(max-width: 1000px) and (orientation: landscape)')
          .matches)
    ) {
      dispatch(closeSidebar());
    }
  };
  
  useEffect(() => {
    if (isSidebarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSidebarOpen]);

  return (
    <nav
      ref={sidebarRef}
      className={`${style.sidebar} ${
        isSidebarOpen ? style.open : style.closed
      }`}
    >
      <div className={style.showHc}>
        <HeaderControls handleLinkClick={handleLinkClick} />
      </div>
      <ul
        className={`${style.navList} ${
          isSidebarOpen ? style.open : style.closed
        }`}
      >
        <NavItem
          to="/"
          Icon={HomeIcon}
          label="sideBar.home"
          onClick={handleLinkClick}
        />
        <NavItem
          to="/products"
          Icon={ProductsIcon}
          label="sideBar.products"
          onClick={handleLinkClick}
        />
        <NavItem
          to="/projects"
          Icon={ProjectsIcon}
          label="sideBar.projects"
          onClick={handleLinkClick}
        />
        <NavItem
          to="/about"
          Icon={AboutIcon}
          label="sideBar.about"
          onClick={handleLinkClick}
        />
        <NavItem
          to="/anti-corruption"
          Icon={AntiCorruptionIcon}
          label="sideBar.antiCorruption"
          onClick={handleLinkClick}
        />
        <NavItem
          to="/appeals"
          Icon={AppealsIcon}
          label="sideBar.appeals"
          onClick={handleLinkClick}
        />
        <NavItem
          to="/contacts"
          Icon={ContactsIcon}
          label="sideBar.contacts"
          onClick={handleLinkClick}
        />
      </ul>
    </nav>
  );
};

export default SideBar;
