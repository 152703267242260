import React from 'react';
import PageTemplate from '../../PageTemplate';
import Banner from 'src/components/Elements/Banner';
import style from './MetalPreparation.module.scss';
import MetalPreparationImage from 'src/assets/client-images/Catalog/Preparation-of-Metal-Structures.jpg';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const MetalPreparation = () => {
  const { t } = useTranslation();

  return (
    <PageTemplate>
      <Helmet>
        <title>
          {t('breadcrumbs.metal_preparation')} | {t('bannerTitles.companyName')}
        </title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Официальный сайт ОАО «Молодечненский завод металлоконструкций»."
        />
        <link rel="canonical" href="https://mzmk.by/products/metal-preparation" />
      </Helmet>
      <Banner titleKey="metalPreparation" />
      <div className={style.metalPreparationSection}>
        <div className={style.contentWrapper}>
          <div className={style.imageContainer}>
            <img src={MetalPreparationImage} alt="Tower" />
          </div>
          <div className={style.textContent}>
            {t('ourProducts.metal_preparation_desc')}
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default MetalPreparation;
