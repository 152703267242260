import React, { useState } from 'react';
import style from './FilterMenu.module.scss';
import { ReactComponent as ArrowDropDown } from 'src/assets/icons/arrows/arrow_drop_down.svg';

const FilterMenu = ({
  categories,
  onCategorySelect,
  selectedCategory,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCategoryClick = (category: any) => {
    onCategorySelect(category);
    setIsOpen(false);
  };

  return (
    <div className={style.filterButtonContainer}>
      <button className={style.filterButton} onClick={() => setIsOpen(!isOpen)}>
        <span className={style.selectedCategory}>{selectedCategory}</span>
        <ArrowDropDown
          className={`${style.arrowIcon} ${isOpen ? style.open : ''}`}
        />
      </button>
      {isOpen && (
        <div className={style.dropdown}>
          {categories.map((category: any) => (
            <button
              key={category}
              className={style.dropdownItem}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterMenu;
