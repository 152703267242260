import React, { useState } from 'react';
import { catalogItems } from 'src/data/catalogData';
import Fuse from 'fuse.js';
import style from './Search.module.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import img_1 from 'src/assets/client-images/Catalog/tensile-structure-net-300x225.jpg';
import img_2 from 'src/assets/client-images/Catalog/BblLLIka.jpg';
import img_3 from 'src/assets/client-images/Catalog/bridges-and-bridge-sections.jpg';
import img_4 from 'src/assets/client-images/Catalog/Bunker-Ramps.jpg';
import img_5 from 'src/assets/client-images/Catalog/Custom-Manufacturing-of-Metal-Structures.jpg';
import img_6 from 'src/assets/client-images/Catalog/Processing-of-Metal-Rolling-and-Blanks.jpg';
import img_7 from 'src/assets/client-images/Catalog/Preparation-of-Metal-Structures.jpg';
import img_8 from 'src/assets/client-images/Catalog/Application-of-Protective-Coatings.jpg';

interface ICatalogItem {
  img: string;
  alt: string;
  title: string;
  description: string;
  link: string;
}

const Search = () => {
  const { t, i18n } = useTranslation();
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<ICatalogItem[]>([]);

  const catalogItems = [
    {
      img: img_1,
      alt: t('ourProducts.metal_structures_alt'),
      title: t('ourProducts.metal_structures'),
      description: t('ourProducts.metal_structures_desc'),
      link: '/products/metal-structures',
    },
    {
      img: img_2,
      alt: t('ourProducts.towers_alt'),
      title: t('ourProducts.towers'),
      description: t('ourProducts.towers_desc'),
      link: '/products/towers',
    },
    {
      img: img_3,
      alt: t('ourProducts.bridges_and_bridge_sections_alt'),
      title: t('ourProducts.bridges_and_bridge_sections'),
      description: t('ourProducts.bridges_and_bridge_sections_desc'),
      link: '/products/bridges-and-bridge-sections',
    },
    {
      img: img_4,
      alt: t('ourProducts.bunker_ramps_alt'),
      title: t('ourProducts.bunker_ramps'),
      description: t('ourProducts.bunker_ramps_desc'),
      link: '/products/bunker-ramps',
    },
    {
      img: img_5,
      alt: t('ourProducts.custom_metal_structures_alt'),
      title: t('ourProducts.custom_metal_structures'),
      description: t('ourProducts.custom_metal_structures_desc'),
      link: '/products/custom-metal-structures',
    },
    {
      img: img_6,
      alt: t('ourProducts.metal_processing_alt'),
      title: t('ourProducts.metal_processing'),
      description: t('ourProducts.metal_processing_desc'),
      link: '/products/metal-processing',
    },
    {
      img: img_7,
      alt: t('ourProducts.metal_preparation_alt'),
      title: t('ourProducts.metal_preparation'),
      description: t('ourProducts.metal_preparation_desc'),
      link: '/products/metal-preparation',
    },
    {
      img: img_8,
      alt: t('ourProducts.protective_paint_coatings_alt'),
      title: t('ourProducts.protective_paint_coatings'),
      description: t('ourProducts.protective_paint_coatings_desc'),
      link: '/products/protective-paint-coatings',
    },
  ];

  const fuse = new Fuse(catalogItems, {
    keys: ['title', 'description'],
    threshold: 0.3,
    includeScore: true,
  });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuery(value);

    if (value.length >= 2) {
      const result = fuse.search(value);
      setResults(result.map((res) => res.item));
    } else {
      setResults([]);
    }
  };

  return (
    <div className={style.searchContainer}>
      <input
        type="text"
        value={query}
        onChange={handleSearch}
        placeholder={t('search.placeholder')}
        className={style.searchInput}
      />
      <div className={style.searchResults}>
        {results.map((item, index) => (
          <Link to={item.link} key={index} className={style.resultItem}>
            <img src={item.img} alt={item.alt} />
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Search;
