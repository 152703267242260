import React, { FC } from 'react';
import style from './Certificate.module.scss';
import { ReactComponent as FileIcon } from 'src/assets/icons/certificates/descriptionFile.svg';

interface ICertificate {
  title: string;
  src: string;
  size: string;
}

const Certificate: FC<ICertificate> = ({ title, src, size }) => {
  return (
    <div className={style.certificate}>
      <a href={src} target="_blank" rel="noopener noreferrer">
        <FileIcon role="img" className={style.icon} />
        <span className={style.title}>{title}</span>
        <span className={style.params}>
          <span>PDF</span>
          {size}
        </span>
      </a>
    </div>
  );
};

export default Certificate;
