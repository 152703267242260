import React, { FC } from 'react';
import style from './LoadMoreButton.module.scss';

interface ILoadMoreButtonProps {
  onClick: () => void;
  isLoading: boolean;
}

const LoadMoreButton: FC<ILoadMoreButtonProps> = ({ onClick, isLoading }) => {
  return (
    <button
      onClick={onClick}
      className={style.loadMoreButton}
      disabled={isLoading}
    >
      {isLoading ? 'Загрузка...' : 'Загрузить ещё'}
    </button>
  );
};

export default LoadMoreButton;
