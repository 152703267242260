import React from 'react';
import A11yPageTemplate from '../../A11yPageTemplate';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const A11yMetalPreparation = () => {
  const { t } = useTranslation();

  return (
    <A11yPageTemplate>
      <Helmet>
        <title>
          {t('breadcrumbs.metal_preparation')} | {t('bannerTitles.companyName')}
        </title>
        <meta name="robots" content="noindex, nofollow" />
        <meta
          name="description"
          content="Страница доступности ОАО «Молодечненский завод металлоконструкций» для пользователей с нарушением зрения. Настройки шрифтов и цветовых схем для удобства просмотра."
        />
      </Helmet>
      <h1>{t('ourProducts.metal_preparation')}</h1>
      <p>{t('ourProducts.metal_preparation_desc')}</p>
    </A11yPageTemplate>
  );
};

export default A11yMetalPreparation;
