import React from 'react';
import style from './A11yCatalog.module.scss';
import { useTranslation } from 'react-i18next';
import { catalogItems } from 'src/data/catalogData';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  setColorScheme,
  selectAccessibility,
} from 'src/features/accessibility/accessibilitySlice';


const A11yCatalog = () => {
  const { t } = useTranslation();
  const { colorScheme } = useSelector(selectAccessibility);

  return (
    <section className={style.a11yCatalog}>
      <h1>{t('ourProducts.title')}</h1>
      <div className={style.catalogGrid}>
          {catalogItems.map((item, index) => (
            <div key={index} className={style.catalogCard}>
              <div className={style.catalogCardContent}>
                <h3>{t(`ourProducts.${item.title}`)}</h3>
                {/* <p>{t(`ourProducts.${item.description}`)}</p> */}
              </div>
              <Link to={item.a11yLink} className={`${style.catalogCardLink} ${colorScheme}-scheme-reverse`}>
                {t('ourProducts.learn_more')}
              </Link>
            </div>
          ))}
        </div>
    </section>
  );
};

export default A11yCatalog;
