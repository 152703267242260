export const getCertificatesData = (t: (key: string) => string) => [
  {
    title: t('certificateFiles.0.title'),
    src: '/documents/Certificates/CertificateOfStateRegistrationOfEnterprise.pdf',
    size: t('certificateFiles.0.size'),
  },
  {
    title: t('certificateFiles.1.title'),
    src: '/documents/Certificates/CertificateOfTechnicalCompetenceAndScopeOfCompetence.pdf',
    size: t('certificateFiles.1.size'),
  },
  {
    title: t('certificateFiles.2.title'),
    src: '/documents/Certificates/CertificateOfWeldingProductionAssessment.pdf',
    size: t('certificateFiles.2.size'),
  },
  {
    title: t('certificateFiles.3.title'),
    src: '/documents/Certificates/CertificateSteelStructuresGOST23118-2019.pdf',
    size: t('certificateFiles.3.size'),
  },
  {
    title: t('certificateFiles.4.title'),
    src: '/documents/Certificates/CompetencyCertificateVisualInspectionWeldsUT.pdf',
    size: t('certificateFiles.4.size'),
  },
  {
    title: t('certificateFiles.5.title'),
    src: '/documents/Certificates/CompetencyCertificateWeldingQualityControl.pdf',
    size: t('certificateFiles.5.size'),
  },
  {
    title: t('certificateFiles.6.title'),
    src: '/documents/Certificates/CertificateOfOwnProductionWorksAndServicesBelTPP.pdf',
    size: t('certificateFiles.6.size'),
  },
  {
    title: t('certificateFiles.7.title'),
    src: '/documents/Certificates/CertificateOfOwnProductionBelTPP.pdf',
    size: t('certificateFiles.7.size'),
  },
  {
    title: t('certificateFiles.8.title'),
    src: '/documents/Certificates/QualityManagementCertificationISO9001-2015.pdf',
    size: t('certificateFiles.8.size'),
  },
  {
    title: t('certificateFiles.9.title'),
    src: '/documents/Certificates/EnvironmentalManagementSystemCertificationISO14001-2017.pdf',
    size: t('certificateFiles.9.size'),
  },
];
