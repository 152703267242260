import React, { useEffect } from 'react';
import style from './NotFoundPage.module.scss';
import PageTemplate from '../../components/ModulesForPages/PageTemplate';
import { ReactComponent as MzmkIcon } from '../../assets/icons/Mzmk_logotype.svg';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = '404 | Страница не найдена';

    if (typeof window !== 'undefined') {
      window.history.replaceState({}, '', '/404');
    }
  }, []);

  return (
    <PageTemplate>
      <Helmet>
        <title>404 {t('breadcrumbs.notFound')} | {t('bannerTitles.companyName')}</title>
        <meta name="robots" content="noindex, nofollow"/>
        <meta name="description" content="Страница не найдена" />
      </Helmet>
      <div className={style.notFoundContainer}>
        <MzmkIcon className={style.icon} />
        <h1 className={style.title}>404 Ошибка: страница не найдена</h1>
        <p className={style.message}>
          Вы можете перейти на <Link to="/">Главную страницу</Link>
        </p>
      </div>
    </PageTemplate>
  );
};

export default NotFoundPage;
