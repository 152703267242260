import React from 'react';
import A11yPageTemplate from '../../A11yPageTemplate';
import { useTranslation } from 'react-i18next';
import style from './A11yTowers.module.scss';
import { Helmet } from 'react-helmet-async';

const A11yTowers = () => {
  const { t } = useTranslation();

  return (
    <A11yPageTemplate>
      <Helmet>
        <title>
          {t('breadcrumbs.towers')} | {t('bannerTitles.companyName')}
        </title>
        <meta name="robots" content="noindex, nofollow" />
        <meta
          name="description"
          content="Страница доступности ОАО «Молодечненский завод металлоконструкций» для пользователей с нарушением зрения. Настройки шрифтов и цветовых схем для удобства просмотра."
        />
      </Helmet>
      <div className={style.a11yTowers}>
        <h1>{t('ourProducts.towers')}</h1>
        <h2>{t('ourProducts.towers_desc.0')}</h2>
        <ul>
          <li>{t('ourProducts.towers_desc.1')}</li>
          <li>{t('ourProducts.towers_desc.2')}</li>
          <li>{t('ourProducts.towers_desc.3')}</li>
          <li>{t('ourProducts.towers_desc.4')}</li>
        </ul>
        <h2>{t('ourProducts.towers_desc.5')}</h2>
        <ul>
          <li>{t('ourProducts.towers_desc.6')}</li>
          <li>{t('ourProducts.towers_desc.7')}</li>
          <li>{t('ourProducts.towers_desc.8')}</li>
          <li>{t('ourProducts.towers_desc.9')}</li>
          <li>{t('ourProducts.towers_desc.10')}</li>
          <li>{t('ourProducts.towers_desc.11')}</li>
        </ul>
        <p>{t('ourProducts.towers_desc.12')}</p>
      </div>
    </A11yPageTemplate>
  );
};

export default A11yTowers;
