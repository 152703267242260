import React from 'react';
import style from './History.module.scss';
import historyImage from 'src/assets/client-images/Objects/oaoMzmk.jpg';
import { useTranslation } from 'react-i18next';

const History = () => {
  const { t } = useTranslation();

  return (
    <section id="history" className={style.history}>
      <div className={style.container}>
        <div className={style.historyContent}>
          <div className={style.historyImage}>
            <img src={historyImage} alt={t('history.title')} />
          </div>
          <div className={style.historyText}>
            <h2>{t('history.title')}</h2>
            <p>{t('history.company_history')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default History;
