import React, { FC } from 'react';
import style from './Footer.module.scss';

import { useTranslation } from 'react-i18next';
import FooterGovLinks from 'src/components/Elements/FooterEl/FooterGovLinks';
import FooterLocation from 'src/components/Elements/FooterEl/FooterLocation';
import FooterEmail from 'src/components/Elements/FooterEl/FooterEmail';
import FooterContacts from 'src/components/Elements/FooterEl/FooterContacts';

interface IFooterProps {
  isSidebarOpen: boolean;
}

const Footer: FC<IFooterProps> = ({ isSidebarOpen }) => {
  const { t } = useTranslation();

  return (
    <footer
      className={`${style.footer} ${
        isSidebarOpen ? style.footerExpanded : style.footerCollapsed
      }`}
    >
      <div className={style.footerInner}>
        <div className={style.footerContainer}>
          <div className={style.footerContent}>
            <FooterContacts />
            <FooterEmail />
            <FooterLocation />
          </div>
          <FooterGovLinks />
        </div>
      </div>
      <div className={style.footerBottom}>
        <div className={style.footerContainer}>
          <p className={style.footerCopy}>{t('Footer.rights_reserved')}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
