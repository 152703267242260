import React, { FC } from 'react';
import PageTemplate from '../../PageTemplate';
import Banner from '../../../Elements/Banner';
import style from './Certificates.module.scss';
import Certificate from 'src/components/Modules/Certificate';
import { useTranslation } from 'react-i18next';
import { getCertificatesData } from 'src/data/certificatesData';

const Certificates = () => {
  const { t } = useTranslation();
  const certificatesData = getCertificatesData(t);

  return (
    <div className={style.certificates}>
      <div className={style.container}>
        <p>{t('certificates.content.0')}</p>
        <ul>
          <li>{t('certificates.content.1')}</li>
          <li>{t('certificates.content.2')}</li>
          <p></p>
        </ul>
        <p>{t('certificates.content.3')}</p>
        <p>{t('certificates.content.4')}</p>
        <p>{t('certificates.content.5')}</p>

        <div className={style.content}>
          <h2>{t('certificates.title')}</h2>
          {certificatesData.map((cert, index) => (
            <Certificate
              key={index}
              title={cert.title}
              src={cert.src}
              size={cert.size}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Certificates;
