import React from 'react';
import PageTemplate from '../../components/ModulesForPages/PageTemplate';
import Banner from 'src/components/Elements/Banner';
import ContactForm from 'src/components/Modules/ContactForm';
import style from './AppealsPage.module.scss';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const AppealsPage = () => {
  const { t } = useTranslation();

  return (
    <PageTemplate>
      <Helmet>
        <title>{t('breadcrumbs.appeals')} | {t('bannerTitles.companyName')}</title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Официальный сайт ОАО «Молодечненский завод металлоконструкций»."
        />
        <link rel="canonical" href="https://mzmk.by/appeals" />
      </Helmet>
      <Banner titleKey="appeals" />
      <div className={style.appealsPage}>
        <div className={style.container}>
          <>
            <p>
            {t('electronicAppeals.intro.0')}&nbsp;
              <a href="https://xn--80abnmycp7evc.xn--90ais/">
              {t('electronicAppeals.intro.1')}
              </a>
              &nbsp;
              <a href="https://xn--80abnmycp7evc.xn--90ais/">
              {t('electronicAppeals.intro.2')}
              </a>
              {'. '}
            </p>
            <p>
            {t('electronicAppeals.languageRequirement')}
            </p>
            <p>
              <a target="_blank" href="/documents/zakon.pdf">
              {t('electronicAppeals.lawReference.0')}
              </a>
              {', '}
              <a target="_blank" href="/documents/zakon2.pdf">
              {t('electronicAppeals.lawReference.1')}
              </a>
              {'. '}
            </p>
          </>
          <>
            <h3>
            {t('electronicAppeals.mandatoryFields')}{' '}
            </h3>
            <ul>
              <li>
              {t('electronicAppeals.mandatoryFieldsList.0')}
              </li>
              <li>{t('electronicAppeals.mandatoryFieldsList.1')}</li>
              <li>{t('electronicAppeals.mandatoryFieldsList.2')}</li>
              <li>{t('electronicAppeals.mandatoryFieldsList.3')}</li>
            </ul>
            <p>
            {t('electronicAppeals.response')}
            </p>
          </>
          <>
            <h3>
            {t('electronicAppeals.notConsidered')}
            {' '}
            </h3>
            <ul>
              <li>{t('electronicAppeals.notConsideredList.0')}</li>
              <li>
              {t('electronicAppeals.notConsideredList.1')}
              </li>
              <li>
                {t('electronicAppeals.notConsideredList.2')}
              </li>
              <li>{t('electronicAppeals.notConsideredList.3')}</li>
              <li>
              {t('electronicAppeals.notConsideredList.4')}
              </li>
              <li>
              {t('electronicAppeals.notConsideredList.5')}
              </li>
              <li>
              {t('electronicAppeals.notConsideredList.6')}
              </li>
              <li>
              {t('electronicAppeals.notConsideredList.7')}
              </li>
              <li>{t('electronicAppeals.notConsideredList.8')}</li>
            </ul>
          </>
        </div>
      </div>
    </PageTemplate>
  );
};

export default AppealsPage;
