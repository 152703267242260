import img_1 from 'src/assets/client-images/Catalog/tensile-structure-net-300x225.jpg';
import img_2 from 'src/assets/client-images/Catalog/BblLLIka.jpg';
import img_3 from 'src/assets/client-images/Catalog/bridges-and-bridge-sections.jpg';
import img_4 from 'src/assets/client-images/Catalog/Bunker-Ramps.jpg';
import img_5 from 'src/assets/client-images/Catalog/Custom-Manufacturing-of-Metal-Structures.jpg';
import img_6 from 'src/assets/client-images/Catalog/Processing-of-Metal-Rolling-and-Blanks.jpg';
import img_7 from 'src/assets/client-images/Catalog/Preparation-of-Metal-Structures.jpg';
import img_8 from 'src/assets/client-images/Catalog/Application-of-Protective-Coatings.jpg';

export const catalogItems = [
  {
    img: img_1,
    alt: 'metal_structures_alt',
    title: 'metal_structures',
    description: 'metal_structures_desc',
    link: '/products/metal-structures',
    a11yLink: '/accessibility/products/metal-structures',
    type: 'product',
  },
  {
    img: img_2,
    alt: 'towers_alt',
    title: 'towers',
    description: 'towers_desc',
    link: '/products/towers',
    a11yLink: '/accessibility/products/towers',
    type: 'product',
  },
  {
    img: img_3,
    alt: 'bridges_and_bridge_sections_alt',
    title: 'bridges_and_bridge_sections',
    description: 'bridges_and_bridge_sections_desc',
    link: '/products/bridges-and-bridge-sections',
    a11yLink: '/accessibility/products/bridges-and-bridge-sections',
    type: 'product',
  },
  {
    img: img_4,
    alt: 'bunker_ramps_alt',
    title: 'bunker_ramps',
    description: 'bunker_ramps_desc',
    link: '/products/bunker-ramps',
    a11yLink: '/accessibility/products/bunker-ramps',
    type: 'product',
  },
  {
    img: img_5,
    alt: 'custom_metal_structures_alt',
    title: 'custom_metal_structures',
    description: 'custom_metal_structures_desc',
    link: '/products/custom-metal-structures',
    a11yLink: '/accessibility/products/custom-metal-structures',
    type: 'service',
  },
  {
    img: img_6,
    alt: 'metal_processing_alt',
    title: 'metal_processing',
    description: 'metal_processing_desc',
    link: '/products/metal-processing',
    a11yLink: '/accessibility/products/metal-processing',
    type: 'service',
  },
  {
    img: img_7,
    alt: 'metal_preparation_alt',
    title: 'metal_preparation',
    description: 'metal_preparation_desc',
    link: '/products/metal-preparation',
    a11yLink: '/accessibility/products/metal-preparation',
    type: 'service',
  },
  {
    img: img_8,
    alt: 'protective_paint_coatings_alt',
    title: 'protective_paint_coatings',
    description: 'protective_paint_coatings_desc',
    link: '/products/protective-paint-coatings',
    a11yLink: '/accessibility/products/protective-paint-coatings',
    type: 'service',
  },
];
