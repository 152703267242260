import React, { FC } from 'react';
import style from './HeaderControls.module.scss';
import { Link } from 'react-router-dom';
import LanguageSwitcher from 'src/components/Elements/LanguageSwitcher';
import { ReactComponent as SearchIcon } from 'src/assets/icons/search.svg';
import { ReactComponent as EyeIconFill } from 'src/assets/icons/visibilityEye_FILL.svg';

interface IHeaderControlsProps {
  handleLinkClick?: () => void;
}

const HeaderControls: FC<IHeaderControlsProps> = ({ handleLinkClick }) => {

  return (
    <div className={style.headerControls}>
      <Link
        to="/accessibility"
        className={style.accessibility__btn}
        onClick={handleLinkClick}
      >
        <EyeIconFill className={style.icon} />
      </Link>
      <LanguageSwitcher />
      <Link
        to="/search"
        className={style.search__btn}
        onClick={handleLinkClick}
      >
        <SearchIcon className={style.icon} />
      </Link>
    </div>
  );
};

export default HeaderControls;
