import React from 'react';
import PageTemplate from '../../PageTemplate';
import Banner from '../../../Elements/Banner';
import style from './Towers.module.scss';
import TowerImage from 'src/assets/client-images/Catalog/BblLLIka.jpg';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const Towers = () => {
  const { t } = useTranslation();

  return (
    <PageTemplate>
      <Helmet>
        <title>
          {t('breadcrumbs.towers')} | {t('bannerTitles.companyName')}
        </title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Официальный сайт ОАО «Молодечненский завод металлоконструкций»."
        />
        <link rel="canonical" href="https://mzmk.by/products/towers" />
      </Helmet>
      <Banner titleKey="towers" />
      <div className={style.towersSection}>
        <div className={style.contentWrapper}>
          <div className={style.imageContainer}>
            <img src={TowerImage} alt="Tower" />
          </div>
          <div className={style.textContent}>
            <h3>{t('ourProducts.towers_desc.0')}</h3>
            <ul>
              <li>{t('ourProducts.towers_desc.1')}</li>
              <li>{t('ourProducts.towers_desc.2')}</li>
              <li>{t('ourProducts.towers_desc.3')}</li>
              <li>{t('ourProducts.towers_desc.4')}</li>
            </ul>
            <h3>{t('ourProducts.towers_desc.5')}</h3>
            <ul>
              <li>{t('ourProducts.towers_desc.6')}</li>
              <li>{t('ourProducts.towers_desc.7')}</li>
              <li>{t('ourProducts.towers_desc.8')}</li>
              <li>{t('ourProducts.towers_desc.9')}</li>
              <li>{t('ourProducts.towers_desc.10')}</li>
              <li>{t('ourProducts.towers_desc.11')}</li>
            </ul>
            <p>{t('ourProducts.towers_desc.12')}</p>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default Towers;
