import React from 'react';
import style from './WhyUsSection.module.scss';
import iconExperience from '../../../assets/icons/date.svg'; // замените путь на правильный
import iconAwards from '../../../assets/icons/medal.svg'; // замените путь на правильный
import iconClients from '../../../assets/icons/clients.svg'; // замените путь на правильный
import { useTranslation } from 'react-i18next';

const WhyUsSection = () => {
  const { t } = useTranslation();

  return (
    <section id="whyUs" className={style.whyUsSection}>
      <div className={style.container}>
        <h2>{t('whyUs.title')}</h2>
        <p className={style.text}>{t('whyUs.company_history')}</p>
        <div className={style.cards}>
          <div className={style.card}>
            <img src={iconExperience} alt={t('whyUs.years_on_market')} className={style.icon} />
            <h3>51</h3>
            <p>{t('whyUs.years_on_market')}</p>
          </div>
          <div className={style.card}>
            <img src={iconAwards} alt={t('whyUs.awards')} className={style.icon} />
            <h3>60</h3>
            <p>{t('whyUs.awards')}</p>
          </div>
          <div className={style.card}>
            <img src={iconClients} alt={t('whyUs.clients')} className={style.icon} />
            <h3>&gt;3000</h3>
            <p>{t('whyUs.clients')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUsSection;
