import React from 'react';
import style from './A11yFooter.module.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LinkIcon } from 'src/assets/icons/link.svg';
import EyeButton from '../../A11yElement/EyeButton';
import { useSelector } from 'react-redux';
import {
  setColorScheme,
  selectAccessibility,
} from 'src/features/accessibility/accessibilitySlice';

const A11yFooter = () => {
  const { t } = useTranslation();
  const { colorScheme } = useSelector(selectAccessibility);

  return (
    <footer className={style.a11yFooter}>
      <div className={style.footerContainer}>
        <div className={`${style.footerContacts} ${style.footerItem}`}>
          <h3>{t('Footer.contactPhone')}</h3>
          <ul>
            <li>
              <a href="tel:+375176770402">+375 (17) 677-04-02</a>
            </li>
            <li>
              <a href="tel:+375257180535">+375 (25) 718-05-35</a>
            </li>
            <li>
              <a href="tel:+375257190168">+375 (25) 719-01-68</a>
            </li>
          </ul>
        </div>
        <div className={`${style.footerWorkingHours} ${style.footerItem}`}>
          <h3>{t('Footer.workingHours')}</h3>
          <ul>
            <li>{t('Footer.hours.monFri')}</li>
            <li>{t('Footer.hours.break')}</li>
            <li>{t('Footer.hours.satSun')}</li>
          </ul>
        </div>
        <div className={`${style.footerEmail} ${style.footerItem}`}>
          <h3>{t('Footer.email')}</h3>
          <ul>
            <li>
              <a href="mailto:rupzmk@yandex.by">rupzmk@yandex.by</a>
            </li>
            <li>
              <a href="mailto:rupzmk@gmail.com">rupzmk@gmail.com</a>
            </li>
          </ul>
        </div>
        <div className={`${style.footerLocation} ${style.footerItem}`}>
          <h3>{t('Footer.location')}</h3>
          <ul>
            <li>
              <a
                rel="noopener"
                target="_blank"
                href="https://yandex.by/maps/-/CDgHAN-X"
              >
                <span>{t('Footer.address')}</span>
              </a>
            </li>
          </ul>
        </div>
        <div className={`${style.footerGovLinks} ${style.footerItem}`}>
          <ul>
            <li>
              <a href="https://president.gov.by/ru">
                <LinkIcon
                  role="img"
                  aria-label="link icon"
                  className={`${style.linkIcon} ${colorScheme}-scheme-icon`}
                />
                <span>{t('Footer.president_site')}</span>
              </a>
            </li>
            <li>
              <a href="https://pravo.by/">
                <LinkIcon
                  role="img"
                  aria-label="link icon"
                  className={`${style.linkIcon} ${colorScheme}-scheme-icon`}
                />
                <span>{t('Footer.legal_portal')}</span>
              </a>
            </li>
            <li>
              <a href="http://mas.gov.by/ru">
                <LinkIcon
                  role="img"
                  aria-label="link icon"
                  className={`${style.linkIcon} ${colorScheme}-scheme-icon`}
                />
                <span>{t('Footer.architecture_ministry')}</span>
              </a>
            </li>
          </ul>
        </div>
        <div className={style.footerBottom}>
          <p className={style.footerCopy}>{t('Footer.rights_reserved')}</p>
          <EyeButton />
        </div>
      </div>
    </footer>
  );
};

export default A11yFooter;
