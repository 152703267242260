import React, { FC } from 'react';
import style from './Card.module.scss';

interface CardProps {
  image: string;
  title: string;
  text: string;
}

const Card: FC<CardProps> = ({ image, title, text }) => {
  return (
    <div className={style.card}>
      <img src={image} alt={text} />
      <h3>{title}</h3>
      <p>{text}</p>
    </div>
  );
};

export default Card;
