import React from 'react';
import style from './ProductionCapacity.module.scss';
import { useTranslation } from 'react-i18next';

const ProductionCapacity = () => {
  const { t } = useTranslation();

  return (
    <div className={style.productionCapacity}>
      <div className={style.container}>
        <div className={style.overview}>
          <h2>{t('productionCapacity.generalInfo.0')}</h2>
          <p>
            {t('productionCapacity.generalInfo.1')} <strong>750</strong>
          </p>
          <p>
            {t('productionCapacity.generalInfo.2')}{' '}
            <strong>{t('productionCapacity.generalInfo.3')}</strong>
          </p>
          <p>{t('productionCapacity.generalInfo.4')}</p>
        </div>

        <div className={style.equipment}>
          <h2>{t('productionCapacity.technicalEquipment.0')}</h2>

          <div className={style.section}>
            <h3>{t('productionCapacity.technicalEquipment.1')}</h3>
            <ul>
              <li>{t('productionCapacity.technicalEquipment.2')}</li>
              <li>
                {t('productionCapacity.technicalEquipment.3')}
                <ul>
                  <li>{t('productionCapacity.technicalEquipment.4')}</li>
                  <li>{t('productionCapacity.technicalEquipment.5')}</li>
                  <li>{t('productionCapacity.technicalEquipment.6')}</li>
                </ul>
              </li>
              <li>
                {t('productionCapacity.technicalEquipment.7')}
                <ul>
                  <li>{t('productionCapacity.technicalEquipment.8')}</li>
                  <li>{t('productionCapacity.technicalEquipment.9')}</li>
                </ul>
              </li>
              <li>{t('productionCapacity.technicalEquipment.10')}</li>
              <li>
                {t('productionCapacity.technicalEquipment.11')}
                <ul>
                  <li>{t('productionCapacity.technicalEquipment.12')}</li>
                  <li>{t('productionCapacity.technicalEquipment.13')}</li>
                </ul>
              </li>
              <li>
                {t('productionCapacity.technicalEquipment.14')}
                <ul>
                  <li>{t('productionCapacity.technicalEquipment.15')}</li>
                  <li>{t('productionCapacity.technicalEquipment.16')}</li>
                  <li>{t('productionCapacity.technicalEquipment.17')}</li>
                  <li>{t('productionCapacity.technicalEquipment.18')}</li>
                </ul>
              </li>
            </ul>
          </div>

          <div className={style.section}>
            <h3>{t('productionCapacity.assembly_welding_section.0')}</h3>
            <ul>
              <li>
                {t('productionCapacity.assembly_welding_section.1')}
                <ul>
                  <li>{t('productionCapacity.assembly_welding_section.2')}</li>
                  <li>{t('productionCapacity.assembly_welding_section.3')}</li>
                  <li>{t('productionCapacity.assembly_welding_section.4')}</li>
                </ul>
              </li>
              <li>
                {t('productionCapacity.assembly_welding_section.5')}
                <ul>
                  <li>{t('productionCapacity.assembly_welding_section.6')}</li>
                  <li>{t('productionCapacity.assembly_welding_section.7')}</li>
                  <li>{t('productionCapacity.assembly_welding_section.8')}</li>
                </ul>
              </li>
            </ul>
          </div>

          <div className={style.section}>
            <h3>{t('productionCapacity.inspection_section.0')}</h3>
            <ul>
              {' '}
              <li>{t('productionCapacity.inspection_section.1')}</li>
              <li>{t('productionCapacity.inspection_section.2')}</li>
              <li>{t('productionCapacity.inspection_section.3')}</li>
              <li>{t('productionCapacity.inspection_section.4')}</li>
              <li>{t('productionCapacity.inspection_section.5')}</li>
            </ul>
          </div>

          <div className={style.section}>
            <h3>{t('productionCapacity.quality_control_section.0')}</h3>
            <ul>
              <li>
                {t('productionCapacity.quality_control_section.1')}
                <ul>
                  <li>{t('productionCapacity.quality_control_section.2')}</li>
                  <li>{t('productionCapacity.quality_control_section.3')}</li>
                  <li>{t('productionCapacity.quality_control_section.4')}</li>
                  <li>{t('productionCapacity.quality_control_section.5')}</li>
                </ul>
              </li>
              <li>{t('productionCapacity.quality_control_section.6')}</li>
              <li>{t('productionCapacity.quality_control_section.7')}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductionCapacity;
