import React from 'react';
import style from './FooterLocation.module.scss';
import { useTranslation } from 'react-i18next';

const FooterLocation = () => {
  const { t } = useTranslation();

  return (
    <div className={style.footerLocation}>
      <h4 className={style.title}>{t('Footer.location')}</h4>
      <div className={style.content}>
        <ul className={style.contactList}>
          <li>
            <i className={style.iconLocation}></i>
            <a
              rel="noopener"
              target="_blank"
              href="https://maps.app.goo.gl/C4SFXX7PStNAz93i9"
            >
              <span>{t('Footer.address')}</span>
            </a>
          </li>
          <li>
            <iframe
              className={style.responsiveMap}
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1957.5121043541951!2d26.802348945105184!3d54.30594309667397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2sby!4v1724827862534!5m2!1sru!2sby"
              width="600"
              height="450"
              style={{ border: '0' }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FooterLocation;
