import React, { FC, useEffect, useRef, useState } from 'react';
import style from './A11yLanguageSwitcher.module.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowDropDown } from 'src/assets/icons/arrows/arrow_drop_down.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  setColorScheme,
  toggleLanguage,
  selectAccessibility,
} from 'src/features/accessibility/accessibilitySlice';

interface IA11yLanguageSwitcherProps {
  onLinkClick?: () => void;  // Определение типа для onLinkClick
}

const A11yLanguageSwitcher: FC<IA11yLanguageSwitcherProps> = ({ onLinkClick }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  // const menuRef = useRef<HTMLDivElement | null>(null);
  const { colorScheme, isLanguageOpen } = useSelector(selectAccessibility);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    dispatch(toggleLanguage());
    onLinkClick && onLinkClick();
  };

  const handleRussianClick = () => {
    changeLanguage('ru');
    onLinkClick && onLinkClick();
  };
  
  const handleBelorussianClick = () => {
    changeLanguage('by');
    onLinkClick && onLinkClick();
  };
  
  const handleEnglishClick = () => {
    changeLanguage('en');
    onLinkClick && onLinkClick();
  };

  // useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
  //       setIsOpen(false);
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => document.removeEventListener('mousedown', handleClickOutside);
  // }, []);

  return (
    <div
      className={`${style.a11yLanguageSwitcher} ${
        isLanguageOpen ? `${colorScheme}-scheme` : ''
      }`}
      // ref={menuRef}
    >
      <button
        className={style.switcherButton}
        onClick={() => dispatch(toggleLanguage())}
        aria-expanded={isLanguageOpen}
        aria-label="Select language"
      >
        Язык:&nbsp;
        {i18n.language === 'ru'
          ? 'РУС'
          : i18n.language === 'by'
          ? 'БЕЛ'
          : 'ENG'}
        <ArrowDropDown
          className={`${style.arrow} ${
            isLanguageOpen
              ? `${colorScheme}-scheme-icon`
              : `${colorScheme}-scheme-icon-reverse`
          } ${isLanguageOpen ? style.open : ''}`}
        />
      </button>

      <div
        className={`${style.dropdownMenu} ${
          isLanguageOpen ? style.show : ''
        } ${colorScheme}-scheme`}
      >
        <button
          className={`${style.dropdownItem} ${
            i18n.language === 'ru' ? style.none : ''
          }`}
          onClick={() => changeLanguage('ru')}
          aria-label="Switch to Russian"
        >
          РУС
        </button>
        <button
          className={`${style.dropdownItem} ${
            i18n.language === 'by' ? style.none : ''
          }`}
          onClick={() => changeLanguage('by')}
          aria-label="Switch to Belorussian"
        >
          БЕЛ
        </button>
        <button
          className={`${style.dropdownItem} ${
            i18n.language === 'en' ? style.none : ''
          }`}
          onClick={() => changeLanguage('en')}
          aria-label="Switch to English"
        >
          ENG
        </button>
      </div>
    </div>
  );
};

export default A11yLanguageSwitcher;
