import React from 'react';
import style from './Management.module.scss';
import { useTranslation } from 'react-i18next';
import { getManagementData } from 'src/data/managementData';
// import image from 'src/assets/icons/Gerb.png'

const Management = () => {
  const { t } = useTranslation();
  const managementData = getManagementData(t);
  // const managementData: IManagementPerson[] = [
  //   {
  //     name: 'Яндовский Александр Александрович',
  //     position:
  //       'Управляющий',
  //     phones: [
  //       { number: '+375(29) 167-13-13', description: 'моб.' },
  //       { number: '+375(17) 270-13-13' },
  //     ],
  //     appointment: 'Вторник с 12:00 – 15:00',
  //   },
  //   {
  //     name: 'Куприянов Дмитрий Николаевич',
  //     position: 'Первый заместитель генерального директора - главный инженер',
  //     phones: [{ number: '+375(25) 718-05-02' }],
  //     appointment: 'Ежедневно с 9:00 – 12:00',
  //   },
  //   {
  //     name: 'Артюх Елена Григорьевна',
  //     position:
  //       'Заместитель генерального директора по идеологической работе и общим вопросам',
  //     phones: [{ number: '+375(25) 718-06-54' }],
  //     appointment: 'Ежедневно с 8:00 – 12:00',
  //   },
  //   {
  //     name: 'Кондрукевич Григорий Викторович',
  //     position:
  //       'Заместитель главного инженера по конструкторско-технологической подготовке документации',
  //     phones: [{ number: '+375(25) 718-01-06' }],
  //     appointment: '',
  //   },
  //   {
  //     name: 'Шупта Михаил Валерьевич',
  //     position:
  //       'Заместитель главного инженера по энерго-механической подготовке производства- начальник участка №1',
  //     phones: [{ number: '+375(25) 743-10-94' }],
  //     appointment: '',
  //   },
  //   {
  //     name: 'Климович Дмитрий Вячеславович',
  //     position: 'Начальник производства',
  //     phones: [{ number: '+375(25) 718-06-23' }],
  //     appointment: '',
  //   },
  //   {
  //     name: 'Самаль Анна Борисовна',
  //     position: 'И.О. Главного бухгалтера',
  //     phones: [{ number: '+375(25) 718-02-81' }],
  //     appointment: 'Ежедневно с 8:00 – 12:00',
  //   },
  //   {
  //     name: 'Журавель Ирина Антоновна',
  //     position:
  //       'Главный экономист – начальник финансового- экономического отдела',
  //     phones: [{ number: '+375(25) 718-05-38' }],
  //     appointment: '',
  //   },
  //   {
  //     name: 'Андриенко Андрей Евгеньевич',
  //     position: 'Начальник отдела маркетинга и сбыта',
  //     phones: [{ number: '+375(25) 718-05-16' }],
  //     appointment: '',
  //   },
  //   {
  //     name: 'Семашкевич Юлия Владимировна',
  //     position: 'Начальник управления правового и кадрового обеспечения',
  //     phones: [{ number: '+375(25) 743-12-95' }],
  //     appointment: '',
  //   },
  //   {
  //     name: 'Кондрашова Наталья Тадеушевна',
  //     position: 'Начальник отдела металла',
  //     phones: [{ number: '+375(25) 781-02-88' }],
  //     appointment: '',
  //   },
  //   {
  //     name: 'Жуковский Владимир Вячеславович',
  //     position: 'Председатель профкома',
  //     phones: [{ number: '+375(25) 718-05-00' }],
  //     appointment: '',
  //   },
  //   {
  //     name: 'Жердева Марина Вячеславовна',
  //     position: 'Секретарь – референт',
  //     phones: [
  //       { number: '+375 (17) 677-04-02', description: 'приемная' },
  //       { number: '+375 (25) 718-05-35', description: 'моб.' },
  //     ],
  //     appointment: '',
  //   },
  // ];

  return (
    <div className={style.management}>
      <div className={style.container}>
        <h2>{t('management.title')}</h2>
        {managementData.map((person, index) => (
          <div key={index} className={style.card}>
            <h3>{person.name}</h3>
            <h4>{person.position}</h4>
            <h5>{t('management.contact')}</h5>
            <ul className={style.phones}>
              {person.phones.map((phone, idx) => (
                <li key={idx}>
                  <a href={`tel:${phone.number}`}>{phone.number}</a>
                  {phone.description && <span>({phone.description})</span>}
                </li>
              ))}
            </ul>
            {/* <ul className={style.emails}>
              {person.emails.map((email, idx) => (
                <li key={idx}>
                  <a href={`mailto:${email}`}>{email}</a>
                </li>
              ))}
            </ul> */}
            {person.appointment ? <h5>{t('management.schedule')}</h5> : ''}
            {person.appointment && <p>{person.appointment}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Management;
