import React from 'react';
import style from './A11yContactsPage.module.scss';
import A11yPageTemplate from 'src/components/Accessibility/A11yModulesForPages/A11yPageTemplate';
import { useTranslation } from 'react-i18next';
import A11yManagement from 'src/components/Accessibility/A11yModules/A11yManagement';
import { Helmet } from 'react-helmet-async';

const A11yContactsPage = () => {
  const { t } = useTranslation();

  return (
    <A11yPageTemplate>
      <Helmet>
        <title>
          {t('breadcrumbs.contacts')} | {t('bannerTitles.companyName')}
        </title>
        <meta name="robots" content="noindex, nofollow" />
        <meta
          name="description"
          content="Страница доступности ОАО «Молодечненский завод металлоконструкций» для пользователей с нарушением зрения. Настройки шрифтов и цветовых схем для удобства просмотра."
        />
      </Helmet>
      <div className={style.a11yContactsPage}>
        <h1>{t('sideBar.contacts')}</h1>
        <h2>{t('management.address')}</h2>
        <p>{t('Footer.address')}</p>
        <h2>{t('management.contact')}</h2>
        <p className={style.tel}>
          <a href="tel:+375176770402">+375 (17) 677-04-02</a>
          <span>({t('management.phones.office')})</span>
        </p>
        <h2>{t('management.email')}</h2>
        <p className={style.email}>
          <a href="mailto:rupzmk@yandex.by">rupzmk@yandex.by</a>
        </p>
        <p>
          <a href="mailto:rupzmk@gmail.com">rupzmk@gmail.com</a>
        </p>
        <A11yManagement />
      </div>
    </A11yPageTemplate>
  );
};

export default A11yContactsPage;
