import React from 'react';
import PageTemplate from '../../PageTemplate';
import Banner from '../../../Elements/Banner';
import style from './MetalStructures.module.scss';

import img_1 from '../../../../assets/client-images/Catalog/tensile-structure-net-300x225.jpg';
import img_2 from '../../../assets/client-images/Catalog/BblLLIka.jpg';
import img_3 from '../../../assets/client-images/Catalog/metal.jpg';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const MetalStructures = () => {
  const { t } = useTranslation();

  return (
    <PageTemplate>
      <Helmet>
        <title>
          {t('breadcrumbs.metal_structures')} | {t('bannerTitles.companyName')}
        </title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Официальный сайт ОАО «Молодечненский завод металлоконструкций»."
        />
        <link rel="canonical" href="https://mzmk.by/products/metal-structures" />
      </Helmet>
      <Banner titleKey="metalStructures" />
      <div className={style.metalStructures}>
        <div className={style.container}>
          <div className={style.content}>
            <div className={style.content__image}>
              <img src={img_1} alt="" />
            </div>
            <div className={style.content__text}>
              <p>{t('ourProducts.metal_structures_desc.0')}</p>
              <p>{t('ourProducts.metal_structures_desc.1')}</p>
              <p>{t('ourProducts.metal_structures_desc.2')}</p>
              <p>{t('ourProducts.metal_structures_desc.3')}</p>
              <p>{t('ourProducts.metal_structures_desc.4')}</p>
              <p>{t('ourProducts.metal_structures_desc.5')}</p>
            </div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default MetalStructures;
